import React, { useState, useRef, useEffect } from "react";
import { getCvBlob } from '../pages/Services/FileService';

import { FaFileUpload } from "react-icons/fa";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn
} from "../pages/Candidat/CandidatForm/Controls/InputFile/InputFile.styles";
import "../pages/Candidat/CandidatForm/Controls/InputFile/InputFile.css"
import { IoCloseSharp } from "react-icons/io5";

const ImageInput = ({selectedFile, setSelectedFile, imageName}) => {
    const fileInputField = useRef(null);
    const [imageUrl, setImageUrl] = useState(null);

    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    }

    const handleNewFileUpload = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    }

    const removeImage = () => {
        setSelectedFile(null);
        setImageUrl(null);
    }

    const handleGetImage = async (imageName) => {
        setSelectedFile((await getCvBlob(imageName)).data);            
    }

    useEffect(() => {
        if (imageName) {
          handleGetImage(imageName);
        }
    }, [])

    useEffect(() => {
        const reader = new FileReader();
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
            reader.onloadend = () => {
                setImageUrl(reader.result);
            }
        }
    }, [selectedFile])

    return (
        <div>
            <div> 
                <FileUploadContainer style={{margin: '5px 0 0 0'}}>
                <DragDropText>Glisser puis déposer l'image</DragDropText>
                <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
                    <FaFileUpload /> fichier
                </UploadFileBtn>
                <FormField
                    type="file"
                    ref={fileInputField}
                    onChange={handleNewFileUpload}
                    accept="image/*"
                />
                </FileUploadContainer>
            </div>
            { selectedFile &&
                (<FileUploadContainer>
                    <IoCloseSharp onClick={removeImage} className="close--icon"/>
                    <img
                        width="100%"
                        src={imageUrl}
                    />
                </FileUploadContainer>)
            }
        </div>
    );
};

export default ImageInput;
