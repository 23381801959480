import { Badge, Box, Button, createStyles, IconButton, makeStyles, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import "./userbox.css";
import { logout } from "../../../pages/Services/AuthentificationService";
import { getUserData } from "../../../utils/FunctionHelpers";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { FiLogOut } from 'react-icons/fi';

const useStyles = makeStyles((theme) => ({
  large: {
    color: "#FFF",
  },
  notifIcons: {
    padding: "0px 7px 0px 7px !important",
    color: "#FFF",
    cursor: "hand"

  },
}));

const StyledBadge = withStyles((theme) =>
  createStyles({
    badge: {
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  })
)(Badge);

const UserBox = ({ history }) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  const roles = { ROLE_DIRECTION: "ADMIN", ROLE_MANAGER: "MANAGER", ROLE_CONSULTANT: "CONSULTANT" };

  const handleHover = () => {
    setIsHovered(true);
  };

  return (
    <Box className=" d-flex header_right iconposistion">
      <div className="profile_info" onMouseEnter={() => handleHover()} onMouseLeave={() => setIsHovered(false)}>
        {localStorage.getItem("token") !== "undefined" ? (
          <div className={classes.large}>
            {getUserData() && getUserData()?.titre + "  " + getUserData()?.lastname.toUpperCase() + "   " + getUserData()?.firstname.toUpperCase()}
          </div>
        ) : (
          ""
        )}

        <div
          {...(isHovered ? { style: { zIndex: 405, opacity: 1, display: "block", top: "30px" } } : "")}
          className="profile_info_iner"
        >
          {localStorage.getItem("token") !== "undefined" ? (
            <>
              <p>{roles[getUserData()?.role]}</p>
              <h5><span style={{ textTransform: "capitalize" }}>{getUserData()?.titre + "  "}</span><span>{getUserData()?.lastname + " "}</span> <span>{getUserData()?.firstname}</span> </h5>
              <div className="profile_info_details">
                <Link to="/profile" style={{ color: "#FFF", fontSize: "0.875rem", marginLeft: "0.5rem" }}>
                  MON PROFILE
                </Link>
                <Button onClick={(e) => logout(history)} style={{ color: "#FFF" }}>
                  Se déconnecter
                </Button>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div style={{ fontSize: "19px", marginLeft: "7%" }}>
        <li>
          <IconButton className={classes.notifIcons} onClick={(e) => logout(history)} data-toggle="tooltip"
            title="Quitter">
            <StyledBadge overlap="rectangular" color="secondary">
              <FiLogOut />
            </StyledBadge>
          </IconButton>
        </li>
      </div>
    </Box>
  );
};

export default withRouter(UserBox);


const UserBoxMobile = ({ history }) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  const roles = { ROLE_DIRECTION: "ADMIN", ROLE_MANAGER: "MANAGER", ROLE_CONSULTANT: "CONSULTANT" };

  const handleHover = () => {
    setIsHovered(true);
  };

  return (
    <Box className=" iconposistion" sx={{ display: "flex", marginLeft: "auto", width: "100%", height: "100%" }}>
      <div className="profile_info" onMouseEnter={() => handleHover()} onMouseLeave={() => setIsHovered(false)}>
        {localStorage.getItem("token") !== "undefined" ? (
          <Box className={classes.large} >
            {getUserData() && getUserData()?.titre + "  " + getUserData()?.lastname.toUpperCase() + "   " + getUserData()?.firstname.toUpperCase()}
          </Box>
        ) : (
          ""
        )}

        <div
          {...(isHovered ? { style: { zIndex: 405, opacity: 1, display: "block", top: "30px" } } : "")}
          className="profile_info_iner"
        >
          {localStorage.getItem("token") !== "undefined" ? (
            <>
              <p>{roles[getUserData()?.role]}</p>
              <h5><span style={{ textTransform: "capitalize" }}>{getUserData()?.titre + "  "}</span><span>{getUserData()?.lastname + " "}</span> <span>{getUserData()?.firstname}</span> </h5>
              <div className="profile_info_details">
                <Link to="/profile" style={{ color: "#FFF", fontSize: "0.875rem", marginLeft: "0.5rem" }}>
                  MON PROFILE
                </Link>
                <Button onClick={(e) => logout(history)} style={{ color: "#FFF" }}>
                  Se déconnecter
                </Button>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div style={{ fontSize: "19px", marginLeft: "7%" }}>
        <li>
          <IconButton className={classes.notifIcons} onClick={(e) => logout(history)} data-toggle="tooltip"
            title="Quitter">
            <StyledBadge overlap="rectangular" color="secondary">
              <FiLogOut />
            </StyledBadge>
          </IconButton>
        </li>
      </div>
    </Box>
  );
};

export const UserBoxMdMode = withRouter(UserBoxMobile);

