import Modal from "@material-ui/core/Modal";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
  Chip,
  IconButton,
} from "@material-ui/core";
import { getAllUsersForTasks } from "../../../../../Services/UserService";
import {
  revealProcess,
  sendProcess,
} from "../../../../../Services/ProcessService";
import { getUserData, capitalise } from "../../../../../../utils/FunctionHelpers";
import { FiSend } from "react-icons/fi";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getDetails } from "../../../../../Services/UserService";
import TagsInput from "../../../../../../utils/TagsInput";
import { withSnackbar } from "../../../../../../utils/SnackbarHOC";
import EditorCustom from "../../../../../../components/EditorCustorm/EditorCustom";
import { AttachFile } from "@material-ui/icons";
import { v4 } from "uuid";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    borderRadius: "10px",
    width: "50em",
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  plus: {
    fontWeight: 500,
    marginBottom: "0.2em",
  },
  buttonLabel: {
    paddingLeft: "0.4em",
    marginLeft: 0,
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginRight: "0.5em",
    fontWeight: "500",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  takeAppointment: {
    color: "rgb(17 122 167)",
  },
}));

const SendCandidatToClientModal = ({
  reloadData,
  setReloadData,
  candidatName,
  missions,
  selectedMission,
  sentDate,
  forReveal,
  isMissionActive,
  candidatEmail,
  candidatTel,
  revealDate,
  candidatSeniorite,
  candidatCommentaire,
  candidatPointFort,
  candidatSoftskills,
  candidatLangue2,
  candidatAnglais,
  candidatActuPackage,
  candidatActuFixe,
  candidatActuVariable,
  candidatActuInteress,
  candidatSouhaitePackage,
  candidatsouhaiteFixe,
  candidatsouhaiteVariable,
  candidatpreAvis,
  candidatdispoLe,
  numCandidat,
  processId,
  snackbarShowMessage
}) => {
  const [uniqueMissions, setUniqueMissions] = useState(missions);
  const mission = uniqueMissions.filter((m) => m.missionId === selectedMission)[0];
  const { idCandidat } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [errors, setErrors] = useState({});
  const [de, setDe] = useState(getUserData().uuid);
  const [cc, setCc] = useState([]);
  const [cci, setCci] = useState([]);
  const [autresPieces, setAutresPieces] = useState([]);
  const [NomUser, setNomUser] = useState(getUserData()?.firstname);
  const [PrenomUser, setPrenomUser] = useState(getUserData()?.lastname);
  const [EmailUser, setEmailUser] = useState(getUserData().email);
  const [FunctionUser, setFunctionUser] = useState(getUserData().fonction);
  const [TelephoneUser, setTelephoneUser] = useState(getUserData().phone);
  const [checked, setChecked] = useState(0);
  const formRef = useRef(null)
  const [users, setUsers] = useState([]);
  const classes = useStyles();
  const [selected, setSelected] = useState([selectedMission]);
  const [dataUpload, setDataUpload] = useState([]);
  const uploadRef = useRef(null);



  const sendMessage = `   
  Bonjour ${uniqueMissions.filter((m) => selected.includes(m.missionId)).map((mission) => capitalise(mission.destinatairePrincipale?.split(' ')[0]))?.join(', ')?.toString()},
  <p>Ci-joint un dossier qui correspond à votre demande ainsi que la synthèse de nos échanges.<p/>
  ${candidatSeniorite ? "<b>SENIORITE : </b>" + candidatSeniorite : ""} ans<p/>
  ${candidatPointFort ? "<b>POINTS FORTS : </b>" + candidatPointFort : ""}<p/>
  ${candidatSoftskills ? "<b>SOFT SKILLS : </b>" + candidatSoftskills : ""}<p/>
  ${candidatCommentaire ? "<b>COMMENTAIRES : </b>" + candidatCommentaire : ""}<p/>
  ${candidatLangue2 == null && candidatAnglais == null ? "" : "<b>LANGUES : </b>"}<p/>
      ${candidatAnglais == null ? "" : (" Anglais : " + candidatAnglais)}<p/>
      ${candidatLangue2 === "null" || candidatLangue2 === undefined || candidatLangue2 === "" ? "" : (" Langues 2 : " + candidatLangue2)}<p/>
      ${[candidatActuPackage, candidatActuFixe, candidatActuVariable, candidatActuInteress, candidatSouhaitePackage, candidatsouhaiteFixe, candidatsouhaiteVariable].every(value => value == 0)
      ? "" : "<p/><b>REMUNERATION (EN K€): </b>"
    }
     ${[candidatActuPackage,
      candidatActuFixe,
      candidatActuVariable,
      candidatActuInteress].every(value => value == 0)
      ? ''
      : `<div><strong>&nbsp; &nbsp;Rémunération actuelle</strong></div><span><ul style="list-style-type: none;">
     ${[null, '0', "", " "].includes(candidatActuPackage) ? "" : `<li>Package : ${candidatActuPackage}</li>\n`}
     ${[null, '0', "", " "].includes(candidatActuFixe) ? "" : `<li>Fixe : ${candidatActuFixe}</li>\n`}
     ${[null, '0', "", " "].includes(candidatActuVariable) ? "" : `<li>Variable : ${candidatActuVariable}</li>\n`}
     ${[null, "undefined", '', "", " "].includes(candidatActuInteress) ? "" : `<li>Intéressement : ${candidatActuInteress}</li>\n`}
  </ul></span>`}
${candidatSouhaitePackage == 0 &&
      candidatsouhaiteFixe == 0 &&
      candidatsouhaiteVariable == 0 &&
      (candidatpreAvis || candidatdispoLe !== "-")
      ? ""
      : `<div><strong>&nbsp; &nbsp;Rémunération souhaitée</strong></div><span><ul style="list-style-type: none;">
    ${[null, '0', "", " "].includes(candidatSouhaitePackage) ? "" : `<li>Package : ${candidatSouhaitePackage}</li>\n`}
    ${[null, '0', "", " "].includes(candidatsouhaiteFixe) ? "" : `<li>Fixe : ${candidatsouhaiteFixe}</li>\n`}
    ${[null, '0', "", " "].includes(candidatsouhaiteVariable) ? "" : `<li>Variable : ${candidatsouhaiteVariable}</li>\n`}
  </ul></span>`}
  ${candidatpreAvis ? "<strong>DISPONIBILITE</strong>  : " + candidatpreAvis : ""}
      ${![null, "", "null"].includes(candidatdispoLe) ? "Disponible le : " + moment(candidatdispoLe).format("DD/MM/yyyy") : ''}
 <p>Si vous souhaitez organiser une rencontre, merci de nous l'indiquer par retour de mail.</p>
Bonne réception,
 `;
  const firstRevealMessage = `Bonjour ${uniqueMissions.filter((m) => selected.includes(m.missionId)).map((mission) => capitalise(mission.destinatairePrincipale?.split(' ')[0]))?.join(', ')?.toString()}, <p/>
Comme convenu, veuillez trouver ci-dessous les coordonnées du dossier N° ${numCandidat} : <p/>
<b>${candidatName}</b><p/>
Mobile : <b>${candidatTel ?? "-"}</b><p/>
Email : <b>${candidatEmail ?? "-"}</b><p/>
Nous venons de l'appeler et lui avons fait part de la bonne nouvelle ainsi que de votre appel imminent. Nous comptons sur votre réactivité pour maintenir son niveau de motivation.<p/>
Merci de nous tenir informés de la date d'entretien convenue pour vous accompagner au mieux.<p/>
Bonne réception,`

  const secondRevealMessage = `Bonjour ${uniqueMissions.filter((m) => selected.includes(m.missionId)).map((mission) => capitalise(mission.destinatairePrincipale?.split(' ')[0]))?.join(', ')?.toString()},<p/>
  Nous nous permettons de vous renvoyer les coordonnées de ce dossier comme déjà transmises, à votre demande le ${sentDate}.
Sauf erreur de notre part, la personne en question n'a toujours pas été contactée par vos services.<p/>
Pour mémoire, nous avons reçu cette personne en entretien, lui avons présenté votre entreprise et informé que souhaitiez la rencontrer. 
Elle est donc à ce jour en attente de votre prise de contact et nous relance.<p/>
Si vous n'êtes plus intéressés par ce dossier merci de nous le signifier par retour de mail.<p/>
Sans retour de votre part sous une semaine nous lui signifierons que sa candidature a finalement été refusée par votre société.<p/>
Comme convenu, veuillez trouver à nouveau ci-dessous ses coordonnées :<p/>
<b>${candidatName}</b></br>
${[null, 'undefined', " "].includes(candidatTel) ? "" : (" <b>Tel  </b> : " + candidatTel)}<p/>
${[null, 'undefined', '', " "].includes(candidatEmail) ? "" : (" <b>Email  </b> : " + candidatEmail)}<p/>
Merci de nous tenir informés de votre retour.<p/>
Cordialement,`;

  const [objet, setObjet] = useState(
    forReveal
      ? `GO&DEV - Coordonnées de ${candidatName} - Dossier Nº ${numCandidat} `
      : `GO&DEV - Dossier Nº ${numCandidat} - ${candidatName} : ${mission.nom_mission}`
  );
  const [signature, setSignature] = useState(
    `
    ${PrenomUser + "  " + NomUser?.toUpperCase()}
    ${FunctionUser}
    ${TelephoneUser}
    ${EmailUser} `
  );

  useEffect(() => {
    setMessage(handleInitiateMessage)
  }, [selected])

  const handleInitiateMessage = () => {
    if (forReveal && revealDate === 'Révéler') {
      return firstRevealMessage
    }
    if (forReveal && revealDate !== 'Révéler') {
      return secondRevealMessage
    }
    return sendMessage
  }

  const [message, setMessage] = useState(handleInitiateMessage());

  const handleSignature = async (id) => {
    setDe(id);
    getDetails(id).then((responseUser) => {
      setSignature(`
      ${responseUser.data.prenom + "  " + responseUser.data.nom?.toUpperCase()}
      ${responseUser.data.fonction}
      ${responseUser.data.telMobile}
      
      ${responseUser.data.email}
      `);
    });
  };


  const isAllSelected =
  uniqueMissions.length > 0 && selected.length === uniqueMissions.length;
  const isIndeterminate =
  uniqueMissions.length > 0 &&
    selected.length > 0 &&
    selected.length !== uniqueMissions.length;
  const validate = () => {
    let temp = {};
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handlecheckChange = (e) => {
      setChecked(e.target.checked ? 1 : 0);
  };

  const handleSelectedCC = (ccs) => {
    setCc(ccs);
  }

  const handleSelectedCCI = (ccis) => {
    setCci(ccis);
  }

  const handleSend = async () => {
    try {
      if (validate()) {
        let selectedMissions = [];
        selected.forEach(element => {
          selectedMissions.push(forReveal ? selectedMission : element)
        });

        const formData = new FormData();
        formData.append('autresPieces', autresPieces);
        formData.append('de', de);
        formData.append('cc', cc);
        formData.append('cci', cci);
        formData.append('objet', objet);
        formData.append('processId', processId);
        formData.append('dateIntegration', null);
        formData.append('date', moment(new Date()).format());
        formData.append('message', message + signature);
        formData.append('candidat', idCandidat);
        dataUpload.map((file, index) => formData.append(`pj[${index}]`, file))

        selectedMissions.forEach((mission, index) => {
          formData.append(`selected[${index}]`, mission);
        });
        formData.append('stats', checked);
        const endpoint = forReveal ? revealProcess : sendProcess;
        snackbarShowMessage("Envoie du mail...", "warning", null);
        await endpoint(formData);
        snackbarShowMessage("Email envoyé.", "success", 4000);
        setReloadData(!reloadData);
        handleClose();
      }
    } catch (error) {
        if( error.response.data.error.code !== 400){
          snackbarShowMessage(error.response.data.error.detail, "error", 4000);
          return;
        }
        snackbarShowMessage(error.message, "error", 4000);
    }
  };



  React.useEffect(() => {
    let active = true;
    if (openModal) {
      (async () => {
        setLoadingUsers(true);
        try {
          const users = await getAllUsersForTasks();
          if (!active) {
            return;
          }
          setUsers(users.data);
          setLoadingUsers(false);
        } catch (error) {
          if (!active) {
            return;
          }
          snackbarShowMessage(error.response.data.error.detail, "error", 4000);
          setLoadingUsers(false);
        }
      })();
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value === "all") {
      setSelected(
        selected.length === uniqueMissions.length
          ? []
          : uniqueMissions.map((mission) => {
            return mission.missionId;
          })
      );
      return;
    }
    if (selected.indexOf(value) !== -1) {
      // if value already present
      const newSelected = selected.filter((s) => s !== value);
      setSelected(newSelected);
    } else {
      // if value not present
      setSelected([...selected, value])
    }
  };

  useEffect(()=> {
    let result = missions.filter((item, index, self) =>
      index === self.findIndex((t) => t.missionId === item.missionId)
    );
    setUniqueMissions(result);
  }, [missions]);

  const listItem = uniqueMissions.map((mission) => {
    return ( 
      mission.missionId == selectedMission && 
      (<div key={mission.missionId}>
        <FormControlLabel
          label={
            mission.nom_societe?.toUpperCase() + " : " + mission.nom_mission
          }
          control={
            <Checkbox
              value={mission.missionId}
              checked={selected.includes(mission.missionId)}
              onChange={handleChange}
            />
          }
        />
        <Typography style={{ marginLeft: "34px" }}>
          A : {mission.destinatairePrincipale}
        </Typography>

        <Typography style={{ marginLeft: "34px" }}>
          {mission.secondaires.length > 0 ? "CC : " : "C : -"}
          {mission.secondaires.map((secondaire, index) => {
            return (
              <span key={`cc_${index}`}>
                <span style={{ textTransform: "capitalize" }}>
                  {(index ? ", " : "") + secondaire.prenom}
                </span>
                <span style={{ textTransform: "uppercase" }}>
                  {" " + secondaire.nom}
                </span>
              </span>
            );
          })}
        </Typography>
      </div>)
    );
  });

  const PourMission = () => {
    const mission = uniqueMissions.filter((m) => m.missionId === selectedMission)[0];
    return (
      <div style={{ margin: "10px 0px" }}>
        <Typography>
          Pour Mission :{" "}
          {mission.nom_mission + " - société : " + mission.nom_societe}
        </Typography>
        <Typography component={"span"}>{"A : "}</Typography>
        <Typography component="span">
          {mission.destinatairePrincipale.charAt(0)?.toUpperCase() +
            mission.destinatairePrincipale.slice(1)}
        </Typography>
        <Typography>
          {mission.secondaires.length > 0 ? "CC : " : "C : -"}
          {mission.secondaires.map((secondaire, index) => {
            return (
              <span key={`cc_${index}`}>
                <span style={{ textTransform: "capitalize" }}>
                  {(index ? ", " : "") + secondaire.prenom}
                </span>
                <span style={{ textTransform: "uppercase" }}>
                  {" " + secondaire.nom}
                </span>{" "}
              </span>
            );
          })}
        </Typography>
      </div>
    );
  };

  const clearFieds = () => {
    setMessage("");
    setObjet(null);
    setCc([]);
    setCci([]);
    setDataUpload([])

  };

  React.useLayoutEffect(() => {
    if (formRef.current) {
      formRef.current.scrollTop = 0
    }
  }, [formRef.current]);

  const editorCustomProps = {
    value: message,
    setValue: setMessage,
    enableOnBlur: false
  }

  const body = (
    <div className={classes.paper}>
      <div className="modal-header">
        <h4 className={classes.modalTitle}>{candidatName}</h4>
      </div>
      <div
        className="modal-body"
        style={{ height: "500px", overflowY: "auto" }}
        ref={formRef}
      >
        <label className="input-label" htmlFor="de">
          DE<span className="required-input">*</span>
        </label>
        {loadingUsers ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel
              {...(errors.de && {
                error: true,
              })}
              shrink
            >
              {errors.de}
            </InputLabel>
            <Select
              value={de}
              name="users"
              onChange={(e) => {
                handleSignature(e.target.value);
              }}
              {...(errors.de && {
                error: true,
              })}
              input={
                <OutlinedInput
                  name="de"
                  {...(errors.de && {
                    labelWidth: 280,
                  })}
                  notched
                ></OutlinedInput>
              }
            >
              {loadingUsers ? (
                <CircularProgress />
              ) : (
                users.map((user) => (
                  <MenuItem value={user.uuid} key={user.uuid}>
                    {`${user.nom} ${user.prenom} (${user.email})`}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}
        <label className="input-label" htmlFor="cci">
          CC
        </label>
        <TagsInput
          selectedTags={handleSelectedCC}
          fullWidth
          id="cc"
          name="cc"
          validation={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/}
          placeholder="Ajouter des CC"
        />
        <FormControlLabel
          control={
            <Checkbox checked={checked===1} onChange={handlecheckChange} />
          }
          label="Ne pas envoyer ce mail (juste pour stats)"
        />{" "}
        <br />
        <label className="input-label" htmlFor="cci">
          CCI
        </label>
        <TagsInput
          selectedTags={handleSelectedCCI}
          fullWidth
          variant="outlined"
          id="cci"
          name="cci"
          placeholder="Ajouter des CCI"
        />
        <label className="input-label" htmlFor="objet">
          OBJET<span className="required-input">*</span>
        </label>
        <TextField
          id="objet"
          value={objet}
          onChange={(e) => setObjet(e.target.value)}
          placeholder="OBJET..."
          margin="normal"
          variant="outlined"
          autoComplete="off"
          fullWidth
          {...(errors.objet && {
            error: true,
            label: errors.objet,
          })}
        />
        {forReveal ? (
          <PourMission />
        ) : (
          <>
            <Box
              sx={{ display: "flex", flexDirection: "column" }}
              style={{ marginBottom: "1em" }}
            >
              {listItem}
            </Box>
          </>
        )}
        <label className="input-label" htmlFor="message">
          MESSAGE
        </label>
        <EditorCustom {...editorCustomProps} />
      </div>
      {dataUpload.length > 0 && (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            height: "100%",
            overflow: "hidden",
            maxWidth: "800px",
            flexWrap: "wrap",
            padding: "10px 0 0 0 "

          }}
          className="input-label"

        >
          <span>PJ:</span>
          {dataUpload.map((file, indexToRemove) =>
            <Chip
              key={v4()}
              label={file.name}
              variant="default"
              onDelete={() => setDataUpload(prevData => prevData.filter((_, index) => index !== indexToRemove))}
              size="small"
            />
          )}

        </Box>
      )}
      <div className="modal-footer">
        {!dataUpload.length > 0 && (
          <Box sx={{ display: "flex" }}>
            <input
              type="file"
              onChange={(e) => setDataUpload(Array.from(e.target.files))}
              style={{ display: "none" }}
              ref={uploadRef}
              multiple
            />
            <IconButton
              // disabled={value.isMail || loading}
              onClick={() => uploadRef.current.click()}
              data-toggle="tooltip"
              title="Ajouter PJ"
            >
              <AttachFile style={{ fontSize: "20px" }} color="primary" />
            </IconButton>
          </Box>
        )}
        <Button
          className={classes.closeButton}
          onClick={handleClose}
          disabled={loading}
        >
          Annuler
        </Button>
        <Button
          startIcon={<FiSend />}
          className={classes.saveButton}
          onClick={handleSend}
          disabled={loading | loadingUsers}
        >
          Envoyer
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>

      </div>
    </div>

  );

  return (
    <>
      {isMissionActive && sentDate !== "" ? (
        <button
          className="btn btn-sm btn-link"
          style={{ textDecoration: "none" }}
          onClick={handleOpen}
        >
          {revealDate ?? sentDate}
        </button>
      ) : (<>{revealDate ?? sentDate}</>)}
      <Modal
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>{body}</Fade>
      </Modal>
    </>
  );
}

export default withSnackbar(SendCandidatToClientModal);
