import {
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { Add, Delete, Edit } from "@material-ui/icons";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { listEmailTypes, deleteEmailType } from "../Services/EmailTypeService";
import MUIDataTable from "mui-datatables";
import { Grid } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { BsPlus } from "react-icons/bs";
import EmailView from "./EmailView";
import { getUserData } from "../../utils/FunctionHelpers";
import '../Dashboard/dashboardStyle.css'
import { theme } from "../../utils/Theme";
import { handleOnViewColumnsChange, displayColumn } from "../../utils/viewColumns";
import "./emailType.css"
import DialogDeleteCustom from "../../components/DialogDeleteCustom/DialogDeleteCustom";
import { withSnackbar } from "../../utils/SnackbarHOC";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: "rgb(17, 122, 167)",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    fontSize: "17px",
  },
  archiveButton: {
    color: "white",
    fontWeight: 500,
    backgroundColor: "rgb(17, 122, 167)",
    marginBottom: "10px",
    "&:hover": {
      backgroundColor: "rgb(17, 122, 167)"
    }
  },
  butnStyle: {
    fontFamily: 'myFirstFont',
  },
  EDITbutton: {
    color: "rgb(245 146 0)",
  }
}));

const EmailTypeList = ({ history, editMode, snackbarShowMessage }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [messageInfos, setMessageInfos] = useState({ uuid: "", msgName: "" });
  const [loadingToDelete, setLoadingToDelete] = useState(false);
  const classes = useStyles();
  const [reloadData, setReloadData] = useState(false);

  const editEmailType = (params) => {
    history.push({
      pathname: `/emailtype/${params.rowData[0]}/edit`,
    });
  };

  const handleOpenDialog = (params) => {
    setOpenDialogDelete(true);
    setMessageInfos({ uuid: params.rowData[0], msgName: params.rowData[1] });
  };

  const handleCloseDialog = () => {
    if (loadingToDelete) {
      return;
    }
    setOpenDialogDelete(false);
  };

  const handleDeleteEmailType = () => {
    (async () => {
      setLoadingToDelete(true);
      try {
        snackbarShowMessage("Patientez svp!", "warning", null);
        const res = await deleteEmailType(messageInfos.uuid);
        snackbarShowMessage(res.data, "success", 4000);
        setLoadingToDelete(false);
        handleCloseDialog();
        setReloadData(!reloadData);
      } catch (error) {
        snackbarShowMessage("une erreur est survenue: " + error.message, "error", 4000);
        setLoadingToDelete(false);
      }
    })();
  };

  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      setMessages([]);
      try {
        let newContacts = await listEmailTypes();

        if (!active) {
          return;
        }

        setMessages(newContacts.data);
        setLoading(false);
      } catch (error) {
        snackbarShowMessage("Erreur de chargement des email types!", "error", 4000);
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  const tableName = "emailColumnVisibility"
  const columns = [
    {
      name: "uuid",
      label: "N°EMAILTYPE",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>{value !== null ? value : "---"}</Box>
            </>
          );
        },
      },
    },
    {
      name: "activite",
      label: "ACTIVITE",
      options: {
        display: displayColumn("activite", tableName),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textTransform: "capitalize" }}>{value}</Box>
            </>
          );
        },
      },
    },
    {
      name: "TypeMsg",
      label: "TYPE",
      options: {
        display: displayColumn("TypeMsg", tableName),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textTransform: "uppercase" }}>{value}</Box>
            </>
          );
        },
      },
    },
    {
      name: "msgName",
      label: "INTITULÉ DU MESSAGE",
      options: {
        display: displayColumn("msgName", tableName),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{value}</Box>
            </>
          );
        },
      },
    },
    {
      name: "objet",
      label: "OBJET DU MAIL",
      options: {
        display: displayColumn("objet", tableName),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                {value ? value : "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "message",
      label: "CONTENU DU MESSAGE",
      options: {
        display: displayColumn("message", tableName),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{value}</Box>
            </>
          );
        },
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <EmailView
                activite={tableMeta.rowData[1]}
                msgName={tableMeta.rowData[3]}
                objet={tableMeta.rowData[4]}
                message={tableMeta.rowData[5]}
                TypeMsg={tableMeta.rowData[2]}
              />
              <IconButton data-toggle="tooltip" title="Modifier" onClick={() => editEmailType(tableMeta)} >
                <Edit className={classes.EDITbutton} />
              </IconButton>
              {localStorage.getItem("rolecurrentuser") === "ROLE_DIRECTION" &&
                getUserData().uuid !== tableMeta.rowData[0] && (
                  <IconButton
                    data-toggle="tooltip"
                    title="Supprimer"
                    onClick={() => handleOpenDialog(tableMeta)}
                    style={{ color: "#EF6262" }}
                  >
                    <Delete />
                  </IconButton>
                )
              }
            </>
          );
        },
      },
    },
  ];

  const isSmMode = useMediaQuery(theme.breakpoints.between(376, 767));


  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  React.useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery((theme) => theme.breakpoints.between(767, 1023));
  const isLargeMode = useMediaQuery((theme) => theme.breakpoints.between(1023, 2800));
  const tableBodyMaxHeight = `${isSmall ? viewportHeight - 292 : isMediumMode ? viewportHeight - 298 : isLargeMode ? viewportHeight - 255 : 200}px`;

  const options = {
    rowsPerPageOptions: [],
    rowsPerPage: 50,
    tableBodyMaxHeight: tableBodyMaxHeight,
    textLabels: {
      body: {
        noMatch: loading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    onViewColumnsChange: (columnName, action) => {
      handleOnViewColumnsChange(columnName, action, tableName);
    },
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
  };

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    handleOpen: openDialogDelete,
    handleDelete: handleDeleteEmailType,
    loading: loadingToDelete,
    label: `Voulez vous vraiment supprimer le message?`,
    title: "Supprimer le message",
  };

  return (
    <Box sx={{ padding: "0 20px" }}>
      <Grid container style={{ paddingBottom: "5px" }}>
        <Grid item xs={10} sm={9} md={9} xl={6}>
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <h2 className="text-capitalize" style={{ color: "rgb(17, 122, 167)" }}>
              LISTE DES MESSAGES TYPES
            </h2>
          </Box>
        </Grid>
        <Grid item xs={2} sm={3} md={3} xl={6}>
          <Box sx={{ display: "flex", justifyContent: "end" }}
          >
            {!isSmMode ? <Button
              className={classes.archiveButton}
              startIcon={<BsPlus size={22} />}
              onClick={() => history.push("/emailtype/add")}
            >
              AJOUTER
            </Button>
              : <IconButton data-toggle="tooltip"
                title="Delete" aria-label="delete" onClick={() => history.push("/emailtype/add")} size="medium" >
                <Add />
              </IconButton>}
          </Box>
        </Grid>
      </Grid>

      <Box className="messageType_container">
        <DialogDeleteCustom {...dialogDeleteProps} />
        <MUIDataTable data={messages} columns={columns} options={options} />
      </Box>
    </Box >
  );
};

export default withRouter(withSnackbar(EmailTypeList));
