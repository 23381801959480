import React, { useRef, useState } from "react"
import { useParams, withRouter } from "react-router-dom"
import { Grid, capitalize, useMediaQuery } from "@material-ui/core"
import Informations from "./Accordions/Informations"
import PostRecherche from "./Accordions/PostRecherche"
import SyntheseConsultant from "./Accordions/SyntheseConsultant"
import Process from "./Tabs/Process"
import moment from "moment"
import "moment/locale/fr"
import {
  Edit,
  NotesOutlined,
  AssignmentOutlined,
  CheckBoxOutlined,
  SettingsOutlined,
} from "@mui/icons-material"
import {
  Accordion,
  Box,
  Button,
  Card,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { BiPhone } from "react-icons/bi"
import {callCandidat, deleteCandidat, disableMailing, getDetails} from "../../Services/CandidatService"
import { addCandidatsToSelection, getSelectionDetails } from "../../Services/SelectionCandidatService"
import Notes from "./Tabs/Notes"
import Collegues from "./Tabs/Collegues"
import Selections from "./Tabs/Selections"
import "../../Contact/VisualiserContact/icons.css"
import SmsCandidat from "../EmailCondidat/SmsCandidat"
import "./visuialisation.css"
import ShowCVCandidat from "./ShowCVCandidat"
import EmailCondidat from "../EmailCondidat/EmailCondidat"
import { getUserData, updateObjectStateValue } from "../../../utils/FunctionHelpers"
import { withSnackbar } from "../../../utils/SnackbarHOC"
import Prospection from "../../Contact/VisualiserContact/Tabs/Prospection"
import { theme } from "../../../utils/Theme";
import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'
import 'react-reflex/styles.css'
import { CheckCircleOutlined } from "@mui/icons-material"
import { LayoutContainer } from "../../../layout/LayoutContainer/LayoutContainer"
import DialogDeleteCustom from "../../../components/DialogDeleteCustom/DialogDeleteCustom"
import Loader from "../../../components/Loader/Loader"
import TabPanelTaches from "../../../components/TabPanelTaches/TabPanelTaches"
import { deleteTacheCandidat, getAll, getAllTachesFaites } from "../../Services/TacheCandidatService"
import { TABLE } from "../../../enums/tablesEnum"
import EmailModal from "../../../components/EmailModal/EmailModal"
import { sendEmailCandidat } from "../../Services/ProcessService"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { ArrowBack } from "@material-ui/icons"
import { Link } from "react-router-dom/cjs/react-router-dom"
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {FaUsers} from "react-icons/fa";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3} px={1} pb={1}>
          {children}
        </Box>
      )}
    </div>
  );
}
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const useStyles = makeStyles(() => ({
  candidatsControl: {
    color: "#FFF",
    backgroundColor: "rgb(17, 122, 167)",
  },
  deleteFromListButton: {
    color: "#FFF",
    backgroundColor: "rgb(239, 98, 98)",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  contactIcons: {
    color: "#117aa7",
    marginLeft: "0px",
  },
  stylefont: {
    fontSize: "18px",
    fontWeight: "bold",
    padding: "0.5em",
    marginBottom: "0em",
    color: "black",
  },
  toggleopen: {
    marginLeft: "7rem",
  },
  toggleclose: {
    paddingLeft: "20rem",
  },
  candidatHeaderInfos: {
    padding: "15px",
    marginTop: "2px",
    marginBottom: "20px",
  },
  candidatInfosCard: {
    backgroundColor: "transparent",
    padding: "0px",
  },
  infosAccordion: {
    margin: "10px",
  },

  candidatInfosBody: {
    boxSizing: "border-box",
    maxHeight: "78vh",
    height: "95%",
    paddingBottom: "20px"
  },

  titleStyle: {
    fontSize: "16px",
    textTransform: "uppercase",
    marginLeft: "0.5em",
    fontWeight: 600,
    fontFamily: "StyleFont",
  },
  tabRoot: {
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiTab-labelIcon': {
      minHeight: 'auto',
      marginRight: '8px',
    },
  }
}))

function VisualiserCandidat({
  selected,
  history,
  snackbarShowMessage,
}) {

  const [candidatDetails, setCandidatDetails] = useState({});
  const { idCandidat } = useParams();
  const idCurrentUser = getUserData().uuid;
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(history.location.state?.tabIndex || 0);
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [loadData, setLoadData] = useState(false);
  const classes = useStyles();
  const [selectionData, setSelectionData] = useState({
    isLoading: false,
    data: {},
  });
  const selectedCandidats = history.location.state
    ? history.location.state.candidatsInList
    : [];
  const [loadingToDisableMailing, setLoadingToDisableMailing] = useState(false);
  const [candidatIndex, setCandidatIndex] = useState(
    history.location.state && history.location.state.candidatIndex
  )
  const [societeId, setSocieteId] = useState(null);
  const { idSelection } = useParams()
  const props = { loadData, setLoadData, snackbarShowMessage, deleteTache: deleteTacheCandidat, getAll, getAllTachesFaites, tacheFrom: TABLE.CANDIDAT, societeId };
  const location = useLocation();
  const [loadingToDelete, setLoadingToDelete] = useState(false)
  const [loadingToCall, setLoadingToCall] = useState(false)
  const mounted = useRef(true)
  const [openDialogDelete, setOpenDialogDelete] = useState(false)
  const [cv, setCv] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [totalCondidat, setTotalCondidat] = useState(0);
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));
  const isMdMode = useMediaQuery(theme.breakpoints.between(481, 992));
  const isLgMode = useMediaQuery(theme.breakpoints.up(992));
  const isLaptopMode = useMediaQuery(theme.breakpoints.between(993, 1280));

  const [filterData, setFilterData] = useState({
    deletedCandidats: [],
    loading: false,
    isFiltering: history.location.state && history.location.state.filtering,
  });

  const handleCloseDialog = () => {
    if (loadingToDelete) {
      return;
    }
    setOpenDialogDelete(false);
  };

  const nextCandidat = () => {
    setCandidatIndex(candidatIndex + 1);
  };

  const previousCandidat = () => {
    setCandidatIndex(candidatIndex - 1);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDialogDelete(true);
  };
  const editCandidat = (idCandidat, history, numCandidat) => {
    history.push({
      pathname: "/candidats/" + idCandidat + "/edit",
      state: {
        ...history.location.state,
        candidatsInList: selectedCandidats,
        candidatIndex: selectedCandidats?.indexOf(
          selectedCandidats[candidatIndex]
        ),
        selection: selectionData.data.id,
      },
      search: `?num-candidat=${numCandidat}`
    });
  };

  const getSelection = () => {
    updateObjectStateValue(setSelectionData, { isLoading: true, data: {} });
    getSelectionDetails(idSelection)
      .then(({ data }) => {
        updateObjectStateValue(setSelectionData, {
          isLoading: false,
          data: data,
        });
      })
      .catch(() => {
        snackbarShowMessage(
          "Erreur de chargement des détails de la sélections.",
          "error",
          4000
        );
      })
      .finally(() => {
        updateObjectStateValue(setSelectionData, { isLoading: false });
      });
  };
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));


  const deleteCandidatFromList = () => {
    (async () => {
      setLoadingToDelete(true);
      try {
        await deleteCandidat(idCandidat);
        snackbarShowMessage("Candidat supprimé avec succès", "success", 4000);
        await delay(1000);
        setLoadingToDelete(false);
        setOpenDialogDelete(false);
        history.replace({
          pathname: `/`,
        });
      } catch (err) {
        snackbarShowMessage(err.data || "An error occurred", "error", 4000);
        setLoadingToDelete(false);
      }
    })();
  };

  const handleSaveFilterToSelection = () => {
    updateObjectStateValue(setFilterData, {
      loading: true,
    });
    addCandidatsToSelection(selectionData.data.uuid, {
      deletedCandidats: filterData.deletedCandidats,
      selectedCandidats,
    })
      .then(({ data }) => {
        snackbarShowMessage(data);
        history.replace({
          pathname: `/selections/${idSelection}/candidats/list`,
          search: `?selectionName=${selectionData.data.selectionName}&quantity=${selectedCandidats.length}&consultant=${candidatDetails.consultant.fullName}`,
        });
      })
      .catch(({ response }) => {
        snackbarShowMessage(response.data.error.detail, "error", 4000);
      })
      .finally(() => {
        updateObjectStateValue(setFilterData, {
          loading: false,
        });
      });
  };

  React.useEffect(() => {
    if (idSelection) {
      getSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelection]);

  React.useEffect(() => {
    if (loadingToDelete === false) {
      if (totalCondidat) {
        setTotalCondidat(totalCondidat - 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingToDelete]);

  React.useEffect(() => {
    let active = true;
    let candidat;
    (async () => {
      setLoading(true);
      try {
        let params = "";
        if (idSelection) {
          params = `?selection=${idSelection}`;
        }
        candidat = await getDetails(idCandidat, params);
        if (!active) {
          return;
        }
        setCv(candidat.data.uploadedFiles);
        setCandidatDetails(candidat.data);
        setNom(candidat.data.nom);
        setPrenom(candidat.data.prenom);
        setEmail(candidat.data.email);
        if (candidat.data?.employeur) {
            setSocieteId(candidat.data.employeur.uuid);
        }
        setLoading(false);
      } catch (err) {
        snackbarShowMessage("Erreur de chargement du candidat.", "error", 4000);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCandidat, reloadData]);

  React.useEffect(() => {
    const etat = new URLSearchParams(location.search).get('etat');
    if (etat === 'updated') {
      snackbarShowMessage("Candidat mis à jour via l'extension", "warning", 4000);
    } else if (etat === 'created') {
      snackbarShowMessage("Candidat ajouté via l'extension", "success", 4000);
    }
  }, [location.search]);

  React.useEffect(() => {
    if(selectedCandidats != undefined) {
      const candidat = selectedCandidats[candidatIndex];
      const state = {
        ...history.location.state,
        candidatsInList: selectedCandidats,
        candidatIndex: selectedCandidats.indexOf(candidat),
      };

      if (candidatIndex !== undefined) {
        const pathname =
            idSelection === undefined
                ? `/candidats/${candidat}/view`
                : `/selections/${idSelection}/candidats/${candidat}/view`;

        history.push({pathname, state});
      }
    }

    if (totalCondidat === null) {
      setTotalCondidat(history.location.state?.numbersTotalOfSelections);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidatIndex]);

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };
  const submitDisableMailing = () => {
    setLoadingToDisableMailing(true)
    snackbarShowMessage("Changement de statut emailing en cours ...", "info", 4000);
    disableMailing(candidatDetails.uuid).then(() => {
      snackbarShowMessage("Changemenet de statut emailing réussi !", "success", 4000);
    }).catch(() => {
      snackbarShowMessage(
          "une erreur s'est produite au moment de Stop emailing",
          "error",
          4000
      );
    }).finally(() => {
      setLoadingToDisableMailing(true)
      window.location.reload();
    })
  }

  const submitCallCandidat = () => {
    setLoadingToCall(true);
    callCandidat({
      candidat_number: candidatDetails.telephone
    }).then(() => {
      snackbarShowMessage("Appel en cours...", 'warning');
    })
      .catch(() => {
        snackbarShowMessage(
          "une erreur s'est produite au moment de l'appel (format invalide)",
          "error",
          4000
        );
      })
      .finally(() => {
        setLoadingToCall(false);
      });
  }

  const ShowSelectionName = () => {
    return (
      <Box
        style={{
          fontStyle: "italic",
          fontSize: "smaller",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Link
          data-toggle="tooltip" title="Returner vers la liste de candidats de la selection."
          to={getUrlSelectedSelection()}
          className="d-print-none font-sm"
          style={{ textDecoration: "none" }}
        >
          <ArrowBack />
        </Link>
        <Typography className={classes.titleStyle}>
          Sélection : {selectionData.isLoading ? "Chargement de la sélection..." : selectionData.data.selectionName}
        </Typography>
      </Box>
    );
  };
  const ShowNumberOfChips = () => {
    let totalSelections = history.location.state?.numbersTotalOfSelections;
    let currentIndex = history.location.state?.candidatIndex;
    let candidatsInList = history.location.state?.candidatsInList;

    if (!totalSelections || !currentIndex || !candidatsInList) {
      const storedState = JSON.parse(localStorage.getItem('viewCandidatState'));
      if (storedState) {
        totalSelections = storedState.numbersTotalOfSelections;
        currentIndex = storedState.candidatIndex;
        candidatsInList = storedState.candidatsInList;
      }
    }

    if (totalSelections !== undefined) {
      return (
          <Box
              sx={{ fontSize: 'smaller', display: "flex", alignItems: "center", height: "100%", width: "100%", justifyContent: "end" }}
          >
            <Typography>
              <strong>Fiche {currentIndex + 1}/{totalSelections}</strong>
            </Typography>
          </Box>
      );
    } else {
      setTimeout(() => {
        snackbarShowMessage(
            "Erreur de chargement de la quantité de fiches.",
            "error",
            4000
        );
      }, 0);

      return null;
    }
  };





  const tabs = [
    { name: "NOTES", icon: <NotesOutlined /> },
    { name: "PROCESS", icon: <SettingsOutlined /> },
    { name: "TÂCHES", icon: <AssignmentOutlined /> },
    { name: "SELECTIONS", icon: <CheckBoxOutlined /> },
    { name: "APPROCHE", icon: <CheckCircleOutlined /> },
    { name: "COLLEGUES", icon: <FaUsers /> },
  ]

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    handleOpen: openDialogDelete,
    handleDelete: deleteCandidatFromList,
    loading: loadingToDelete,
    label: `Voulez vous vraiment supprimer le candidat ${candidatDetails.prenom}  ${candidatDetails.nom}?`
  }

  const getUrlSelectedSelection = () => {
    const url = JSON.parse(window.localStorage.getItem('data_vivier_selected'))
    return url;
  }

  return (
    <LayoutContainer>
      {loading ? (
        <Loader />
      ) : (
        <>
          {(selected && selectedCandidats != undefined) && (
            <Grid container spacing={1} >
              <Grid item xs={12} md={3} lg={4} sm={3}>{idSelection && <ShowSelectionName />}</Grid>
              <Grid item xs={12} md={8} lg={7} sm={8}>
                <Box sx={{ marginBottom: "1rem", display: "flex", width: "100%", gap: "10px" }}>
                  <Button
                    variant="contained"
                    className={classes.candidatsControl}
                    disabled={candidatIndex === 0 ? true : false}
                    onClick={() => previousCandidat()}
                  >
                    Précédent
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.deleteFromListButton}
                    onClick={handleOpenDeleteDialog}
                    disabled={loadingToDelete}
                  >
                    Supprimer
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.candidatsControl}
                    disabled={
                      candidatIndex === selectedCandidats.length - 1
                        ? true
                        : false
                    }
                    onClick={() => nextCandidat()}
                  >
                    Suivant
                  </Button>
                  {filterData.isFiltering && (
                    <Button
                      variant="contained"
                      className={classes.candidatsControl}
                      onClick={handleSaveFilterToSelection}
                      disabled={filterData.loading}
                    >
                      Sauvegarder
                      {filterData.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={1} lg={1} sm={1} textAlign="right" >{(selected && idSelection) && <ShowNumberOfChips />}</Grid>
            </Grid>
          )}
          <Card className={classes.candidatHeaderInfos}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7} md={7} lg={8}>
                <Box
                  style={{
                    fontSize: !isSmMode || !isMdMode ? "26px" : "19px",
                    flexDirection: isSmMode || isMdMode || isLaptopMode ? "column" : ""
                  }}
                  className="candidadat_number_date_updated_zoom">
                  <Box style={{
                    display: "flex",
                    color: "#117aa7",
                    alignItems: "baseLine",

                  }}>
                    <span
                      style={{
                        marginRight: "8px",
                      }}
                    >
                      {candidatDetails.titre}
                    </span>
                    <span
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {candidatDetails.prenom}
                    </span>
                    <span
                      style={{
                        textTransform: "uppercase",
                        marginLeft: "0.5em",
                      }}
                    >
                      {candidatDetails.nom}
                    </span>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    alignItems: "baseLine",
                    gap: "5px"
                  }}
                  >
                    <span
                      style={{
                        fontSize: !isSmMode ? "1.4rem" : "1.2rem",
                        marginRight: "1.2rem"
                      }}
                    >
                      {candidatDetails.numCandidat ? "N° " + candidatDetails.numCandidat : ""}
                    </span>
                    <span
                      style={isSmMode || isMdMode ? { fontSize: "14px" } : {}}
                      className="content_police_990px_1024px"
                    >
                      {candidatDetails.updatedAt !== null ?
                        "mise à jour  :  " +
                        moment(candidatDetails.updatedAt)
                          .locale("fr")
                          .format("DD MMMM YYYY - HH:mm") : ""
                      }
                    </span>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5} md={5} lg={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Box
                  sx={{ display: "flex", width: "100%", justifyContent: isMdMode || isLgMode ? "flex-end" : "flex-start", alignItems: "center" }}
                  className="buttonIcons"
                >
                  <FormControlLabel
                      control={<Checkbox
                          checked={!candidatDetails.isMailingAutorized}
                          onClick={submitDisableMailing}
                          disabled={loadingToDisableMailing}
                      />}
                      label={
                        <Typography style={{ fontFamily: 'Roboto', fontSize: '16px', fontWeight: 'bold' }}>
                          STOP EMAILING
                        </Typography>
                      }
                  />
                  <Button
                    startIcon={<BiPhone />}
                    data-toggle="tooltip"
                    title="APPEL"
                    className={classes.contactIcons}
                    onClick={submitCallCandidat}
                    disabled={loadingToCall}
                  ></Button>
                  <SmsCandidat
                    candidatTel={candidatDetails.telephone}
                    candidatFullName={
                      capitalize(prenom) + " " + nom.toUpperCase()
                    }
                    candidatFirstName={prenom}
                    candidatLastName={nom.toUpperCase()}
                    candidatId={idCandidat}
                    loadData={loadData}
                    setLoadData={setLoadData}
                  />
                  <EmailModal
                    loadData={loadData}
                    setLoadData={setLoadData}
                    prenom={prenom}
                    emailTarget={email}
                    serviceEmail={sendEmailCandidat}
                    id={idCandidat}
                    category={TABLE.CANDIDAT}
                  />
                  <Button
                    data-toggle="tooltip"
                    title="MODIFIER"
                    onClick={() => editCandidat(idCandidat, history, candidatDetails.numCandidat)}
                    style={{
                      color: "#117aa7"
                    }}
                  >
                    <Edit />
                  </Button>
                  <ShowCVCandidat {...{ files: cv, idCandidat, setReloadData, reloadData  }} />
                </Box>
              </Grid>
            </Grid>
          </Card>
          <Box className={selected ? "contentWithHeadSelectionOpened" : "contentWithHeadSelectionClosed"} >
            <ReflexContainer orientation="horizontal">
              <ReflexElement className="left-pane" >
                <Box className={classes.candidatInfosBody}>
                  <Card className={classes.candidatInfosCard}>
                    <Box sx={{
                      boxSizing: "border-box",
                      height: "100%",
                      paddingTop: "15px"
                    }}
                    >
                      <Accordion
                        defaultExpanded
                        className={classes.infosAccordion}
                      >
                        <Informations {...candidatDetails} ><h3 className={classes.stylefont}>INFORMATIONS</h3></Informations>
                      </Accordion>
                      <Accordion
                        defaultExpanded
                        style={{ backgroundColor: "transparent" }}
                      >
                        <PostRecherche {...candidatDetails} />
                      </Accordion>
                      <Accordion
                        defaultExpanded
                        style={{
                          margin: isSmMode ? "1em 0em 3em 0em" : "1em 1em 3em 1em",
                          width: "auto",
                          boxShadow: "rgb(0 0 0 / 80%) 0px 0px 10px",
                          top: "27px",
                        }}
                      >
                        <div>
                          <h3 className={classes.stylefont}>SYNTHESE</h3>
                        </div>
                        <div>
                          <SyntheseConsultant {...candidatDetails} />
                        </div>
                      </Accordion>
                    </Box>
                  </Card>
                </Box>
              </ReflexElement>
              <ReflexSplitter style={{ cursor: "move" }}>
                <Box
                  sx={{
                    backgroundColor: "#117aa7",
                    color: "white",
                    overflowX: "auto"
                  }}>
                  <Tabs
                    value={value}
                    onChange={handleTabs}
                    orientation="horizontal"
                  >
                    {
                      tabs.map(tab => (<Tab key={tab.name} style={{ minWidth: 50 }} label={
                        <Box sx={{ display: "flex", gap: 5, alignItems: "center" }} >
                          {tab.icon}
                          {!isSmMode && <span>{tab.name}</span>}
                        </Box>} />))
                    }
                  </Tabs>
                </Box>
              </ReflexSplitter>
              <ReflexElement className="right-pane" maxSize={!selected ? 600 : 530} minSize={50} style={{ overflow: "hidden" }}>
                <Box sx={{ background: '#ddd' }}>
                  <Box sx={{ backgroundColor: "#f4f7fc", marginTop: "40px" }}>
                    {[
                      { index: 0, component: <Notes {...props} /> },
                      {
                        index: 1,
                        component: (
                          <Process
                            {...props}
                            candidatName={capitalize(prenom) + " " + nom}
                            candidatEmail={candidatDetails.email}
                            candidatTel={candidatDetails.telephone}
                            candidatAccountManager={candidatDetails.accountManager}
                            candidatConsultant={candidatDetails.consultant}
                            candidatSeniorite={candidatDetails.seniorite}
                            candidatCommentaire={candidatDetails.commentaire}
                            candidatPointFort={candidatDetails.pointsforts}
                            candidatSoftskills={candidatDetails.softskills}
                            candidatLangue2={candidatDetails.langue2}
                            candidatAnglais={candidatDetails.anglais}
                            candidatActuPackage={candidatDetails.actuellePackage}
                            candidatActuFixe={candidatDetails.actuelleFixe}
                            candidatActuVariable={candidatDetails.actuelleVariable}
                            candidatActuInteress={candidatDetails.actuelleInteressement}
                            candidatSouhaitePackage={candidatDetails.souhaitePackage}
                            candidatsouhaiteFixe={candidatDetails.souhaiteFixe}
                            candidatsouhaiteVariable={candidatDetails.souhaiteVariable}
                            candidatsouhaiteInteress={candidatDetails.souhaiteInteress}
                            candidatpreAvis={candidatDetails.preAvis}
                            candidatdispoLe={candidatDetails.dispoLe}
                            numCandidat={candidatDetails.numCandidat}
                            processId={candidatDetails.processId}
                          />
                        ),
                      },
                      { index: 2, component: <TabPanelTaches {...props} /> },
                      { index: 3, component: <Selections {...props} /> },
                      { index: 4, component: <Prospection {...props} /> },
                      { index: 5, component: <Collegues {...props} /> },
                      ].map((tabPanel) => (
                      <TabPanel
                                           key={tabPanel.index}
                                           value={value}
                                           index={tabPanel.index}
                                         >
                      {tabPanel.component}
                      </TabPanel>
                      ))}
                    </Box>
                  </Box>
                </ReflexElement>
              </ReflexContainer>
            </Box>
            <DialogDeleteCustom {...dialogDeleteProps} />
          </>
        )
        }
    </LayoutContainer>
  );
}

export default withRouter(withSnackbar(VisualiserCandidat));
