import React, {useCallback, useEffect, useState} from "react";
import {updateObjectStateValue} from "../../../../utils/FunctionHelpers";
import {useParams} from "react-router-dom";
import {getCandidatsAndContacts} from "../../../Services/CandidatService";
import {CircularProgress} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import {withSnackbar} from "../../../../utils/SnackbarHOC";
import moment from "moment/moment";
import {LinkedIn} from "@material-ui/icons";



const Collegues = ({ snackbarShowMessage, societeId }) => {
    const [collegues, setCollegues] = useState({
        isLoading: false,
        data: []
    })


    const loadData = useCallback(() => {
        updateObjectStateValue(
            setCollegues,
            { isLoading: true, data: [] }
        );
        getCandidatsAndContacts(societeId)
            .then(({ data }) => {
                updateObjectStateValue(
                    setCollegues,
                    { isLoading: true, data: data.data }
                );
            })
            .catch(() => {
                snackbarShowMessage("Erreur de chargements des collegues", "error", 4000);
            })
            .finally(() => {
                updateObjectStateValue(
                    setCollegues,
                    { isLoading: false }
                );
            });
    }, [societeId, snackbarShowMessage]);

    useEffect(() => {
        loadData()
    }, [loadData]);

    const columns = [
        {
            name: "name",
            label: "NOM",
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: () => ({ style: { textAlign: "center", padding: "2px 12px" } }),
                setCellProps: () => ({ style: { padding: "0px 12px" } }),
            }
        },
        {
            name: "fonction",
            label: "FONCTION",
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: () => ({ style: { textAlign: "center", padding: "2px 12px" } }),
                setCellProps: () => ({ style: { padding: "0px 12px" } }),
            }
        },
        {
            name: "date",
            label: "MAJ",
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: () => ({ style: { textAlign: "center", padding: "2px 12px" } }),
                setCellProps: () => ({ style: { padding: "0px 12px" } }),
            }
        },
        {
            name: "linkedin",
            label: "LINKEDIN",
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: () => ({ style: { textAlign: "center", padding: "2px 12px" } }),
                setCellProps: () => ({ style: { padding: "0px 12px" } }),
                customBodyRender: (value) => {
                    return <><a href={value} target="_blank" rel="noreferrer">
                        <LinkedIn />
                    </a></>;
                }
            }
        },
        {
            name: "telephone",
            label: "TELEPHONE",
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: () => ({ style: { textAlign: "center", padding: "2px 12px" } }),
                setCellProps: () => ({ style: { padding: "0px 12px" } }),
                customBodyRender: (value) => {
                    return value ? value : "-";
                }
            }
        },
        {
            name: "consultant",
            label: "TALENT MANAGER",
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: () => ({ style: { textAlign: "center", padding: "2px 12px" } }),
                setCellProps: () => ({ style: { padding: "0px 12px" } }),
                customBodyRender: (value) => {
                    return value ? value : "-";
                }
            }
        },
        {
            name: "Statut",
            label: "STATUT",
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: () => ({ style: { textAlign: "center", padding: "2px 12px" } }),
                setCellProps: () => ({ style: { padding: "0px 12px" } }),
            }
        }
    ]
    const options = {
        textLabels: {
            body: {
                noMatch: collegues.isLoading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
            },
        },
        pagination: true,
        selectableRows: "none",
        draggableColumns: {
            enabled: true,
        },
        filterType: "dropdown",
        print: false,
        download: false,
        viewColumns: false,
        filter: false,
        search: false,
        responsive: "standard",
        rowsPerPage: 10,
        tableBodyMaxHeight: "200px",
    };

    return (
        <>
            <MUIDataTable data={collegues.data} columns={columns} options={options} />
        </>
    )
}
export default withSnackbar(Collegues);

