/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, withRouter } from "react-router-dom/cjs/react-router-dom";
import { withSnackbar } from "../../utils/SnackbarHOC";
import { useEffect, useState } from "react";
import { theme } from "../../utils/Theme";
import { Box, Tab, Tabs, Typography, useMediaQuery } from "@material-ui/core";
import { Card, Grid } from "@mui/material";
import ComparativeTable from "./Components/ComparativeTable";
import MonthlyEvolutionCharts from "./Components/MonthlyEvolutionCharts";
import KPIChart from "./Components/BarChart";
import BarChartt from "./Components/BarChart";
import FilterPilote from "./Components/FilterPilote";
import { getConsultantsAndManagers } from "../Services/UserService";
import { getAll } from "../Services/MissionService";
import Loader from "../../components/Loader/Loader";
import { Diversity1, ManageAccounts } from "@mui/icons-material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  getPilotageClients,
  getPilotageConsultants,
} from "../Services/PilotageService";
import moment from "moment";
import { capitalise } from "../../utils/FunctionHelpers";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import Sourcing from "./Components/Sourcing/Sourcing";
import { getAllImportationSource } from "../Services/SourceService";
import { Place } from "@material-ui/icons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Pilot = ({ snackbarShowMessage }) => {
  const [indexTab, setIndexTab] = useState(0);
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));
  const { filters } = useSelector((state) => state.filtersKPIConsultants);
  const [sourcesIds, setSourcesIds] = useState([]);

  const filtersMissions = useSelector(
    (state) => state.filtersKPIMissions.filters
  );

  const initialChartsData = {
    prod: {
      id: "prod",
      list: [
        `Prequalif (${0})`,
        `Envois client (${0})`,
        `Propales (${0})`,
        `Signatures (${0})`,
      ],
      values: [0, 0, 0, 0],
    },
    kpi: {
      id: "kpi",
      list: [
        `TR (Envois/préqualif): ${0}%`,
        `TP (Propales/Préqualif): ${0}%`,
        `TS (Sign/Préqualif): ${0}%`,
      ],
      values: [0, 0, 0],
    },
  };
  const [chartsData, setChartsData] = useState(initialChartsData);
  const initialMonths = Array(12)
    .fill(0)
    .map((_, index) => capitalise(moment().month(index).format("MMM")));

  const initialEvolutionData = initialMonths.map((month) => ({
    Prequalif: 0,
    EnvoisClient: 0,
    Propales: 0,
    Signatures: 0,
    month,
  }));

  const [evolutionData, setEvolutionData] = useState(initialEvolutionData);
  const [compareData, setCompareData] = useState([]);
  const [missionData, setMissionData] = useState([]);
  const location = useLocation();

  const resetData = () => {
    setChartsData(initialChartsData);
    setEvolutionData(initialEvolutionData);
    setCompareData([]);
  };

  const resetParamsUrl = () => {
    const params = new URLSearchParams();
    params.append("statut", "910f357b-0b13-4f66-89a8-5c06964c1dcf");
    return params;
  };

  const getInitialParameters = () => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.toString()) return searchParams;
    return resetParamsUrl();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await getAll(getInitialParameters());
        setMissionData(data.missions);
      } catch (error) {
        snackbarShowMessage(error.message, "error", 4000);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams();
      queryParams.append("role", "ROLE_CONSULTANT");
      const search = `?${queryParams.toString()}`;
      try {
        const { data } = await getConsultantsAndManagers(search);
        setManagers(data.managers);
        data.managers
          .filter((consultant) => consultant.uuid)
          .map((consultant) => consultant.uuid);
      } catch (error) {
        snackbarShowMessage(
          "Erreur de chargement des consultants et managers.",
          "error",
          4000
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = () => {
      resetData();
      if (indexTab === 0) {
        loadPilotageConsultant(
          filters.selectedConsultants,
          filters.startDate,
          filters.endDate
        );
      } else if (indexTab === 1) {
        loadPilotageClients(
          filtersMissions.selectedMissions,
          filtersMissions.startDate || filters.startDate,
          filtersMissions.endDate || filters.endDate
        );
      } else if (indexTab === 2) {
        handleSourcing(
          filtersMissions.startDate || filters.startDate,
          filtersMissions.endDate || filters.endDate
        );
      } else {
        resetData();
      }
    };
    fetchData();
  }, [filters, filtersMissions, indexTab]);

  const loadData = (queryParams, requestService) => {
    setLoading(true);
    requestService(queryParams)
      .then(({ data }) => {
        const { Production, KPI, Evolution, Comparatif } = data;
        let localEvolutionData = [];
        setChartsData({
          prod: {
            id: "prod",
            list: [
              `Prequalif (${Production["Prequalif"]})`,
              `Envois client (${Production["Envois client"]})`,
              `Propales (${Production["Propale"]})`,
              `Signatures (${Production["Embauche/Integration"]})`,
            ],
            values: [
              Production["Prequalif"],
              Production["Envois client"],
              Production["Propale"],
              Production["Embauche/Integration"],
            ],
          },
          kpi: {
            id: "kpi",
            list: [
              `TR (Envois/préqualif): ${KPI["TR"]}%`,
              `TP (Propales/Préqualif): ${KPI["TP"]}%`,
              `TS (Sign/Préqualif): ${KPI["TS"]}%`,
            ],
            values: [KPI["TR"], KPI["TP"], KPI["TS"]],
          },
        });
        Object.keys(Evolution).forEach((month) => {
          localEvolutionData.push({
            Prequalif: Evolution[month]["Prequalif"],
            Revelations: Evolution[month]["Envois client"],
            Propales: Evolution[month]["Propale"],
            Signatures: Evolution[month]["Embauche/Integration"],
            month,
          });
        });
        setEvolutionData(localEvolutionData);
        setCompareData(Comparatif);
      })
      .catch((error) => {
        snackbarShowMessage("Erreur de chargement de données.", "error", 4000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadPilotageConsultant = (consultants, startDate, endDate) => {
    const queryParams = `?consultants[]=${consultants.join(
      "&consultants[]="
    )}&startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(
      endDate
    ).format("YYYY-MM-DD")}`;
    loadData(queryParams, getPilotageConsultants);
  };

  const loadPilotageClients = (missions, startDate, endDate) => {
    const queryParams = `?missions[]=${missions.join(
      "&missions[]="
    )}&startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(
      endDate
    ).format("YYYY-MM-DD")}`;
    loadData(queryParams, getPilotageClients);
  };

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  const handleSourcing = async (startDate, endDate) => {
    setLoading(true);
    const queryParams = new URLSearchParams();
    queryParams.append("startDate", moment(startDate).format("YYYY-MM-DD"));
    queryParams.append("endDate", moment(endDate).format("YYYY-MM-DD"));
    const search = `?${queryParams.toString()}`;
    try {
      const { data } = await getAllImportationSource(search);
      setSourcesIds(data);
    } catch (error) {
      snackbarShowMessage(
        "Erreur de chargement de sources d'importation!",
        "error",
        4000
      );
    } finally {
      setLoading(false);
    }
  };

  const kpiDatas = [
    {
      title: "PRODUCTION",
      component: <BarChartt data={chartsData.prod} />,
      cols: 6,
    },
    {
      title: "KPI",
      component: <KPIChart data={chartsData.kpi} />,
      cols: 6,
    },
    {
      title: "EVOLUTION MENSUELLE SUR 12 MOIS",
      component: <MonthlyEvolutionCharts data={evolutionData} />,
      cols: 12,
    },
    {
      title:
        "TABLEAU COMPARATIF ENTRE CONSULTANTS SUR LA PLAGE DE DATES CHOISIE",
      component: (
        <ComparativeTable
          {...(indexTab === 1 ? { showTsp: true } : { showTsp: false })}
          isLoading={loading}
          data={compareData}
        />
      ),
      cols: 12,
    },
  ];

  const sourcingProps = {
    sourcesIds,
    loading,
  };

  const filterProps = {
    consultants: managers,
    indexTab,
    missionData,
  };

  const GenerateChartGrid = (data) => (
    <Grid container spacing={2}>
      {data.map((item, index) => (
        <Grid
          key={v4()}
          item
          xs={12}
          sm={12}
          md={item.cols}
          lg={item.cols}
          style={index < 2 ? { paddingTop: "0px" } : {}}
        >
          <Card style={{ padding: "30px 20px", marginTop: "10px" }}>
            <strong>{item.title}</strong>
            {item.component}
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Box
      sx={{
        padding: "0 20px",
      }}
    >
      <Box
        sx={{ padding: 5, display: "flex", gap: "10px", marginBottom: "20px" }}
      >
        <Typography
          className="text-capitalize"
          style={{
            color: "rgb(17, 122, 167)",
            fontSize: "35px",
          }}
        >
          PILOTAGE
        </Typography>
        <Tabs
          value={indexTab}
          onChange={(event, newValue) => setIndexTab(newValue)}
          indicatorColor={loading ? "none" : "secondary"}
          aria-label="full width tabs example"
        >
          {[
            { title: "PROD. et KPI Consultants", icon: <ManageAccounts /> },
            { title: "PROD. et KPI Clients", icon: <Diversity1 /> },
            { title: "Sourcing", icon: "" },
          ].map((tab, index) => (
            <Tab
              key={v4()}
              label={
                <Box sx={{ display: "flex", gap: 5, alignItems: "center" }}>
                  {tab.icon}
                  {!isSmMode && <span>{tab.title}</span>}
                </Box>
              }
              {...a11yProps(index)}
              disabled={loading}
            />
          ))}
        </Tabs>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          position: "relative",
        }}
      >
        {loading && (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              zIndex: 120,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "rgba(255, 255, 255, 0.6)",
            }}
          >
            <Loader />
          </Box>
        )}
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              overflowY: "auto",
              height: "calc(100vh - 140px)",
            }}
            order={2}
          >
            {[kpiDatas, kpiDatas].map((data, index) => (
              <TabPanel
                value={indexTab}
                keys={v4()}
                index={index}
                dir={theme.direction}
              >
                {GenerateChartGrid(data)}
              </TabPanel>
            ))}
            <TabPanel value={indexTab} index={2} dir={theme.direction}>
              <Sourcing {...sourcingProps}></Sourcing>
            </TabPanel>
          </Grid>
        </Grid>
        <FilterPilote {...filterProps} />
      </Box>
    </Box>
  );
};

export default withRouter(withSnackbar(Pilot));
