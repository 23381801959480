import {
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { listReportingClient } from "../Services/ReportingClientService";
import MUIDataTable from "mui-datatables";
import { Grid } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { getListDataEspaceMission, getAll } from "../Services/MissionService";
import { getAll as getAllContacts } from "../Services/ContactService";
import moment from "moment";
import "moment/locale/fr";
import {
  getAllContactsInSocieteForMission,
  getDetails,
} from "../Services/SocieteService";
import { getConsultantsAndManagers } from "../Services/UserService";
import DownloadXLSFile from "./DownloadXLSFile";
import { withSnackbar } from "../../utils/SnackbarHOC";
import { RemoveRedEye } from "@mui/icons-material";
import { displaySwipeableDrawerSummaryCandidat } from "../../redux/SwipeableDrawer/actions/swipeableDrawerActions";
import { useDispatch } from "react-redux";
import ShowCVCandidat from "../Candidat/VisualiserCandidat/ShowCVCandidat";
import { extractDate } from "../../utils/AppointmentsUtils";
import ShowAddCvCandidat from "../Candidat/VisualiserCandidat/ShowAddCvCandidat";

const ReportingClient = ({ snackbarShowMessage }) => {
  const [page, setPage] = useState(1);
  const [listData, setListData] = useState({
    societes: [],
    statutsMission: [],
  });
  const [count, setCount] = useState(0);
  const [reloadData, setReloadData] = useState(false);
  const [data, setData] = useState([]);
  const [loadSociete, setLoadSociete] = useState(false);
  const [loadMissions, setLoadMissions] = useState(false);
  const [reloadMissions, setReloadMissions] = useState(false);
  const [reloadContacts, setReloadContacts] = useState(false);
  const [reloadAccountManagers] = useState(false);
  const [reloadConsultant] = useState(false);
  const [loadContacts, setLoadContacts] = useState(false);
  const [loadAccountManagers, setLoadAccountManagers] = useState(false);
  const [loadConsultants, setloadConsultants] = useState(false);
  const [reportingClient, setReportingClient] = useState([]);
  const [statut, setStatut] = useState(0);
  const [societe, setSociete] = useState("null");
  const [societeName, setSocieteName] = useState("");
  const [contact, setContact] = useState([]);
  const [mission, setMission] = useState([]);
  const [accountManager, setAccountManager] = useState([]);
  const [listMissions, setListMissions] = useState({ missions: [] });
  const [listContacts, setListContacts] = useState({ contacts: [] });
  const [listAccountManager, setListAccountManager] = useState({
    accountManager: [],
  });
  const [listConsultants, setConsultants] = useState({ accountManager: [] });
  const [consultant, setconsultant] = useState([]);

  const [categorie, setCategorie] = useState("null");
  const [loading, setLoading] = useState(false);
  const [tablePage, setTablePage] = useState(0);
  const dispatch = useDispatch()

  const handlePageChange = (page) => {
    setTablePage(page);
    setPage(page + 1);
  }
  let today = new Date();
  let dateExport =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
  let fileName = dateExport;

  const addParam = (e) => {
    setCategorie(e.target.value);
  };

  const buildParams = () => {
    const params = new URLSearchParams();
    const fields = {
      page,
      limit: 100,
      statut: statut === 0 ? null : statut,
      mission,
      contact,
      societe: (societe !== "null") ? societe : undefined,
      accountManager,
      consultant,
    };

    Object.entries(fields).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(key, item);
        });
      } else if (value !== undefined) {
        params.append(key, value);
      }
    });

    return params;
  };

  React.useEffect(() => {
    let active = true;
    (async () => {
      try {
        if (societe != "null") {
          const societeDetails = await getDetails(societe);
          setSocieteName(societeDetails.data.nom_societe);
        }
        if (!active) {
          return;
        }
      } catch (error) {
        setLoadMissions(false);
        snackbarShowMessage("Erreur de chargement du detail de la société.", "error", 4000);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadSociete]);

  React.useEffect(() => {
    let active = true;
    (async () => {
      setLoadAccountManagers(true);
      setloadConsultants(true);
      try {
        const response = await getConsultantsAndManagers();
        if (!active) {
          return;
        }
        setConsultants({ accountManager: response.data.managers });
        setloadConsultants(false);
        setListAccountManager({
          accountManager: response.data.managers,
        });
        setLoadAccountManagers(false);
      } catch (error) {
        if (!active) {
          return;
        }
        setloadConsultants(false);
        setLoadAccountManagers(false);
        snackbarShowMessage("Erreur de chargement des données des listes.", "error", 4000);
      }
    })();
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadAccountManagers, reloadConsultant]);

  React.useEffect(() => {
    let active = true;
    (async () => {
      setLoadContacts(true);
      try {
        if (societe !== "null") {
          const contactResponse = await getAllContactsInSocieteForMission(
            societe
          );
          setListContacts({ contacts: contactResponse.data });
        } else {
          const response2 = await getAllContacts(page);
          const data = [];
          response2.data.contacts.map((element) =>
            data.push({
              nom: element.nom + " " + element.prenom,
              uuid: element.uuid,
            })
          );
          setListContacts({ contacts: data });
        }
        if (!active) {
          return;
        }
        setLoadContacts(false);
      } catch (error) {
        if (!active) {
          return;
        }
        setLoadContacts(false);
        snackbarShowMessage("Erreur de chargement des données des listes.", "error", 4000);
      }
    })();
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadContacts]);

  React.useEffect(() => {
    let active = true;
    (async () => {
      setLoadSociete(true);
      try {
        const responseListData = await getListDataEspaceMission();
        if (!active) {
          return;
        }
        setListData({
          societes: responseListData.data.societesClients,
          statutsMission: [{ uuid: 0, statut: "TOUS" }, ...responseListData.data.statutMission],
        });
        setLoadSociete(false);
      } catch (error) {
        if (!active) {
          return;
        }
        setLoadSociete(false);
        snackbarShowMessage("Erreur de chargement des données des listes.", "error", 4000);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadMissions, reloadContacts]);
  React.useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);
      try {
        const params = buildParams();
        const newReportingClient = await listReportingClient(params);
        if (isSubscribed) {
          const missionWithoutDuplicate = newReportingClient.data.map(item => ({ uuid: item.missionId, nom_mission: item.nom_mission }))
            .filter((obj, index, array) => array.findIndex(item => item.uuid === obj.uuid) === index)
            .sort((a, b) => a.nom_mission.localeCompare(b.nom_mission));
          setListMissions({ missions: missionWithoutDuplicate })
          setData(newReportingClient.data)
          setReportingClient(newReportingClient.data);
          setCount(newReportingClient.data.count);
          setLoading(false);
        }
      } catch (error) {
        if (isSubscribed) {
          setLoading(false);
          snackbarShowMessage("Erreur de chargement du reporting client.", "error", 4000);
        }
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, [reloadData, page]);

  const handleReset = () => {
    setMission([])
    setContact([]);
    setAccountManager([]);
    setconsultant([])
  }


  const filterDataByTypeAndIds = (type, ids) => {
    if (ids.length === 0) { setReportingClient(data); return null; }
    const response = data.filter(item => {
      if (type === "MISSION") {
        return ids.includes(item.missionId);
      } else if (type === "CONTACT") {
        return ids.includes(item.condidatId);
      } else if (type === "CONSULTANT") {
        return ids.includes(item.consultantId);
      } else if (type === "ACCOUNT MANAGER") {
        return ids.includes(item.accountManagerId);
      }
      return setReportingClient(data);
    });
    setReportingClient(response)
  }

  const handleShowFilters = () => {
    const categories = {
      mission: {
        label: "MISSION",
        value: mission,
        setValue: setMission,
        data: listMissions.missions,
        displayProperty: "nom_mission",
        load: loadMissions,
      },
      contact: {
        label: "CONTACT",
        value: contact,
        setValue: setContact,
        data: listContacts.contacts,
        displayProperty: "nom",
        load: loadContacts,
      },
      am: {
        label: "ACCOUNT MANAGER",
        value: accountManager,
        setValue: setAccountManager,
        data: listAccountManager.accountManager,
        displayProperty: (element) => element.user,
        load: loadAccountManagers,
      },
      consultant: {
        label: "TALENT MANAGER",
        value: consultant,
        setValue: setconsultant,
        data: listConsultants.accountManager,
        displayProperty: (element) => element.user,
        load: loadConsultants,
      },
    };

    const category = categories[categorie];
    if (!category) {
      return false;
    }

    return (
      <Grid item xs={12} md={3}>
        <strong>{category.label}</strong>
        <FormControl variant="outlined" fullWidth margin="normal">
          {category.load ? (
            <Box display="flex" minHeight="100" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Select
              multiple
              name={categorie}
              value={category.value}
              onChange={(e) => {
                category.setValue(e.target.value);
              }}
              onBlur={() => filterDataByTypeAndIds(category.label, category.value)}
              renderValue={(selected) =>
                category.data
                  .filter((item) => selected.includes(item.uuid))
                  .map((element) =>
                    typeof category.displayProperty === "function"
                      ? category.displayProperty(element)
                      : element[category.displayProperty]
                  )
                  .join(", ")
              }
            >
              {category.data.map((element) => (
                <MenuItem value={element.uuid} key={element.uuid}>
                  <Checkbox
                    checked={category.value.indexOf(element.uuid) > -1}
                  />
                  <ListItemText
                    primary={
                      typeof category.displayProperty === "function"
                        ? category.displayProperty(element).toUpperCase()
                        : element[category.displayProperty].toUpperCase()
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </Grid>
    );
  };

  const columns = [
    {
      name: "condidatId",
      label: "ID",
      options: {
        filter: true,
        display: false,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value ? value.substring(0, value.indexOf("-")) : "-"}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  width: "150px",
                }}
              >
                {value ? value.substring(0, value.indexOf("-")) : "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "numCandidat",
      label: "N° CANDIDAT",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta) => {
          return (<ShowAddCvCandidat {...{ snackbarShowMessage, files: tableMeta.rowData[15] }}>
            {value}
          </ShowAddCvCandidat>)
        },
      },
    },
    {
      name: "nom_mission",
      label: "MISSION",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  width: "150px",
                }}
              >
                {value}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "condidatName",
      label: "CANDIDAT",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  width: "200px",
                }}
              >
                {!(isSmall || isMediumMode) && <IconButton
                  onClick={() => dispatch(displaySwipeableDrawerSummaryCandidat({ show: true, id: tableMeta.rowData[0] }))}
                  data-toggle="tooltip"
                  title="Voir resumé">
                  <RemoveRedEye size={"15px"} /></IconButton>}
                <Link
                  to={{ pathname: `/candidats/${tableMeta.rowData[0]}/view` }}
                >
                  {value}
                </Link>
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "statut",
      label: "STATUT",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  width: "150px",
                }}
              >
                {value}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "sent",
      label: "ENVOI",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                fata-toggle="tooltip"
                title={value != null ? moment(value).format("DD/MM/yyyy") : "-"}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  width: "150px",
                }}
              >
                {value != null ? moment(value).format("DD/MM/yyyy") : "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "revealed",
      label: "RÉVÉLÉ",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value != null ? moment(value).format("DD/MM/yyyy") : "-"}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  width: "150px",
                }}
              >
                {value != null ? moment(value).format("DD/MM/yyyy") : "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "e1",
      label: "E1",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value != null ? moment(value).format("DD/MM/yyyy") : "-"}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  width: "150px",
                }}
              >
                {value != null ? moment(value).format("DD/MM/yyyy") : "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "e2",
      label: "E2",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value != null ? moment(value).format("DD/MM/yyyy") : "-"}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  width: "150px",
                }}
              >
                {value != null ? moment(value).format("DD/MM/yyyy") : "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "e3",
      label: "E3",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value != null ? moment(value).format("DD/MM/yyyy") : "-"}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  width: "150px",
                }}
              >
                {value != null ? moment(value).format("DD/MM/yyyy") : "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "e4",
      label: "E4",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value != null ? moment(value).format("DD/MM/yyyy") : "-"}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  width: "150px",
                }}
              >
                {value != null ? moment(value).format("DD/MM/yyyy") : "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "e5",
      label: "E5",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value != null ? moment(value).format("DD/MM/yyyy") : "-"}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  width: "150px",
                }}
              >
                {value != null ? moment(value).format("DD/MM/yyyy") : "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "proposition",
      label: "PROPOSITION",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  width: "150px",
                }}
              >
                {value ? value : "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "signature",
      label: "SIGNATURE",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  width: "150px",
                }}
              >
                {value ? value : "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "commentaire",
      label: "COMMENTAIRE",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  width: "150px",
                }}
              >
                {value ? value : "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "uploadedFiles",
      label: "uploadedFiles",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
            </>
          );
        },
      }
    }
  ];

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery((theme) =>
    theme.breakpoints.between(767, 1023)
  );
  const isLargeMode = useMediaQuery((theme) =>
    theme.breakpoints.between(1023, 2800)
  );

  const tableBodyMaxHeight = `${isSmall
    ? viewportHeight - 292
    : isMediumMode
      ? viewportHeight - 380
      : isLargeMode
        ? viewportHeight - 349
        : 200
    }px`;

  const options = {
    tableBodyMaxHeight: tableBodyMaxHeight,
    rowsPerPage: 50,
    page: tablePage,
    onChangePage: handlePageChange,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment."
        ),
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handlePageChange(tableState.page);
          break;
        default:
      }
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filter: false,
    print: false,
    sort: true,
    columns: false,
    responsive: "standard",
    search: false,
    download: false,
    viewColumns: false,
  };

  const reportingClientCustom = reportingClient?.map((row) => {
    const updatedRow = {
      ...row,
      sent: (row.sent?.date != null) ? moment(row?.sent?.date).toDate().getTime() : null,
      revealed: (row.revealed?.date != null) ? moment(row?.revealed?.date).toDate().getTime() : null,
      e1: extractDate(row?.appointments, /RDV 1|E1/),
      e2: extractDate(row?.appointments, /RDV 2|E2/),
      e3: extractDate(row?.appointments, /RDV 3|E3/),
      e4: extractDate(row?.appointments, /RDV 4|E4/),
      e5: extractDate(row?.appointments, /RDV 5|E5/),
    };
    return updatedRow;
  });

  return (
    <Box
      sx={{
        padding: "0 20px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={1} style={{ paddingBottom: "5px" }}>
        <Grid item xs={10} sm={8} md={8} xl={6}>
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <h2
              className="text-capitalize"
              style={{ color: "rgb(17, 122, 167)" }}
            >
              REPORTING - CLIENT
            </h2>
          </Box>
        </Grid>
        <Grid item xs={2} sm={4} md={4} xl={6}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <DownloadXLSFile
              csvData={reportingClientCustom}
              fileName={fileName}
              clientName={societeName}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ marginBottom: "auto" }}>
        <h5
          style={{
            paddingTop: "10px",
            fontWeight: "normal",
            fontStyle: "italic",
          }}
        >
          Date - {moment().locale("fr").format("MMMM D, YYYY")}{" "}
        </h5>
        <Box>
          <Card style={{ padding: "1em", marginBottom: "1em" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={3} xl={3}>
                <strong>SOCIÉTÉ</strong>
                <FormControl variant="outlined" fullWidth margin="normal">
                  {loadSociete ? (
                    <Box display="flex" justifyContent="center">
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Select
                      name="societe"
                      value={societe}
                      onChange={(e) => {
                        setReportingClient([]);
                        setSociete(e.target.value);
                        setReloadMissions(!reloadMissions);
                        setReloadContacts(!reloadContacts);
                        setReloadData(!reloadData);
                      }}
                    >
                      <MenuItem value="null" key="tous">
                        TOUS
                      </MenuItem>
                      {loadSociete ? (
                        <Box
                          display="flex"
                          minHeight="100"
                          justifyContent="center"
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        listData.societes.map((societe) => (
                          <MenuItem value={societe.uuid} key={societe.uuid}>
                            {societe.nomSociete.toUpperCase()}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} md={3} xl={3}>
                <strong>STATUT MISSIONS</strong>
                <FormControl variant="outlined" fullWidth margin="normal">
                  {loadSociete ? (
                    <Box display="flex" justifyContent="center">
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Select
                      name="statut"
                      value={statut}
                      onChange={(e) => {
                        setReportingClient([]);
                        setStatut(e.target.value);
                        setReloadData(!reloadData);
                      }}
                    >
                      {loadSociete ? (
                        <Box
                          display="flex"
                          minHeight="100"
                          justifyContent="center"
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        listData.statutsMission.map((statut) => (
                          <MenuItem value={statut.uuid} key={statut.uuid}>
                            {statut.statut}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} md={3} xl={3}>
                <strong>RECHERCHER PAR :</strong>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <Select
                    value={categorie}
                    name="categorie"
                    onChange={(e) => {
                      addParam(e);
                    }}
                    input={<OutlinedInput name="categorie"></OutlinedInput>}
                  >
                    {[
                      { value: "null", label: "TOUS", key: "tous" },
                      { value: "mission", label: "MISSION", key: "mission" },
                      { value: "contact", label: "CONTACT", key: "contact" },
                      { value: "am", label: "ACCOUNT MANAGER", key: "am" },
                      { value: "consultant", label: "TALENT MANAGER", key: "consultant" },
                    ].map((item) => (
                      <MenuItem key={item.key} value={item.value} onClick={() => { handleReset(); setReportingClient(data) }}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {handleShowFilters()}
            </Grid>
          </Card>
          <Grid item xs={12}>
            <MUIDataTable
              data={reportingClientCustom}
              columns={columns}
              options={options}
            />
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
export default withSnackbar(withRouter(ReportingClient));
