import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {
    Box,
    Card,
    Checkbox,
    FormControl, FormHelperText,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import EcoleModal from "./EcoleModal";
import "./etapes.css";
import { getAllEcoles } from "../../../Services/EcoleService";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { getAllCompetencesSectoriellesForSelect } from "../../../Services/CompetenceSectorielleService";
import { getAllCompetencesMetiersForSelect } from "../../../Services/CompetenceMetierService";
import { getAllCadidatSelections, getAllCandidatSelections } from "../../../Services/SelectionCandidatService";

export const Etape2 = ({
                         idCandidat,
                         competencesSectorielles,
                         competencesMetiers,
                         competencesTechniques,
                         ecole,
                         anglais,
                         langue2,
                         actuPackage,
                         actuFixe,
                         actuVariable,
                         actuInteressement,
                         actuAutres,
                         souhaitPackage,
                         souhaitFixe,
                         souhaitVariable,
                         souhaitAutres,
                         souhaitTjm,
                         setCompetencesSectorielles,
                         setCompetencesMetiers,
                         setCompetencesTechniques,
                         setEcole,
                         setAnglais,
                         setLangue2,
                         setActuPackage,
                         setActuFixe,
                         setActuVariable,
                         setActuInteressement,
                         setActuAutres,
                         setsouhaitPackage,
                         setSouhaitFixe,
                         setSouhaitVariable,
                         setSouhaitAutres,
                         setSouhaitTjm,
                         preAvis,
                         setPreAvis,
                         errors,
                         setErrors,
                         dispoLe,
                         setDispoLe,
                         snackbarShowMessage,
                         setCandidatSelectionsData,
                         candidatSelectionsData,
                         setReloadData,
                         reloadData,
                       }) => {
  const [options, setOptions] = useState([]);
  const [competenceSectoriellesSelect, setCompetenceSectoriellesSelect] = useState([]);
  const [competencesMetiersSelect, setCompetencesMetiersSelect] = useState([]);
  const [selectionsData, setSelectionsData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false)


  function getUserData() {
    return JSON.parse(localStorage.getItem("userData"));
  }
  const checkAdmin = () => {
    const userRole = getUserData()?.role;
    if (userRole && userRole.includes('ROLE_DIRECTION')) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };

  useEffect(() => {
    checkAdmin();
  }, []);


  const handleDisponibilite = (value) => {
    var formats = [
      moment.ISO_8601,
      "MM/DD/YYYY  :)  HH*mm*ss",
      "DD/MM/YYYY",
      "D M d Y H:i:s e+",
    ];
    if (moment(value, formats, true).isValid()) {
      setDispoLe(value);
      setPreAvis("");
      return;
    }
    setDispoLe(null);
    setPreAvis(value);
  };

  React.useEffect(() => {
    arrSec()
    let active = true;
    (async () => {
      try {
        const response = await getAllCadidatSelections(`?archived=${false}`)
        if (!active) {
          return;
        }
        setSelectionsData(response.data.candidatsSelections);
      } catch (error) {
        if (!active) {
          return;
        }
        snackbarShowMessage("Erreur de chargement des sélections.", "error", 4000)
      }
    })();
  }, []);

  React.useEffect(() => {
    arrSec()
    let active = true;
    (async () => {
      try {
        const candidatSelections = await getAllCandidatSelections(idCandidat);
        if (!active) {
          return;
        }
        setCandidatSelectionsData(pluck(candidatSelections.data.selectionsCandidat, 'id'));
      } catch (error) {
        if (!active) {
          return;
        }
        snackbarShowMessage("Erreur de chargement des sélections du candidat.", "error", 4000)
      }
    })();
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  React.useEffect(() => {
    let active = true;
    let ecoleData;

    (async () => {
      try {
        ecoleData = await getAllEcoles();
      } catch (err) {
        snackbarShowMessage("Erreur de chargements des diplomes.", "error", 4000);
      } finally {
        if (active) {
          setOptions(ecoleData.data);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [reloadData]);

  React.useEffect(() => {
    let active = true;
    (async () => {
      try {
        const response = await getAllCompetencesSectoriellesForSelect();
        if (!active) {
          return;
        }
        setCompetenceSectoriellesSelect(response.data);
      } catch (error) {
        if (!active) {
          return;
        }
        snackbarShowMessage("Erreur de chargement des competences sectorielles.", "error", 4000)
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    arrSec()
    let active = true;
    (async () => {
      try {
        const response = await getAllCompetencesMetiersForSelect();
        if (!active) {
          return;
        }
        setCompetencesMetiersSelect(response.data);
      } catch (error) {
        if (!active) {
          return;
        }
        snackbarShowMessage("Erreur de chargement des competences metiers.", "error", 4000)
      }
    })();
    return () => {
      active = false;
    };
  }, []);

  const handleChange = (event) => {
    setCompetencesSectorielles(event.target.value);
  };

  const handleChangeMetier = (event) => {
    setCompetencesMetiers(event.target.value);
  };

  const pluck = (arr, key) => arr.map(i => i[key]);

  const arrSec = () => {
    if (competencesSectorielles.length > 0) {

      setCompetencesSectorielles(pluck(competencesSectorielles, 'uuid'))
    }
    if (competencesMetiers.length > 0) {

      setCompetencesMetiers(pluck(competencesMetiers, 'uuid'))

    }
  };

  return (
      <>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px", height: "100%" }}>
            <Card className="cards" style={{ flex: 2 }}>
              <h4>FORMATION / COMPÉTENCES</h4>
              <label className="input-label">COMPÉTENCES SECTORIELLES</label>
              <FormControl variant="outlined" fullWidth margin="normal">
                <Select
                    multiple
                    name="competencesSectorielles"
                    value={competencesSectorielles}
                    onChange={handleChange}
                    renderValue={(selected) =>
                        competenceSectoriellesSelect
                            .filter((item) => selected.includes(item.uuid))
                            .map((competence) => {
                              return competence.secteur;
                            })
                            .join(", ")
                    }
                >
                  {competenceSectoriellesSelect.map((competence) => (
                      <MenuItem value={competence.uuid} key={competence.uuid}>
                        <Checkbox
                            checked={
                                competencesSectorielles.indexOf(competence.uuid) > -1
                            }
                        />
                        <ListItemText primary={competence.secteur} />
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <label className="input-label">COMPÉTENCES METIERS</label>
              <FormControl variant="outlined" fullWidth margin="normal">
                <Select
                    multiple
                    name="competencesMetiers"
                    value={competencesMetiers}
                    onChange={handleChangeMetier}
                    renderValue={(selected) =>
                        competencesMetiersSelect
                            .filter((item) => selected.includes(item.uuid))
                            .map((competence) => {
                              return competence.metier;
                            })
                            .join(", ")
                    }
                >
                  {competencesMetiersSelect.map((competence) => (
                      <MenuItem value={competence.uuid} key={competence.uuid}>
                        <Checkbox
                            checked={competencesMetiers.indexOf(competence.uuid) > -1}
                        />
                        <ListItemText primary={competence.metier} />
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <label className="input-label">COMPÉTENCES TECHNIQUES</label>
              <TextField
                  onChange={(e) => setCompetencesTechniques(e.target.value)}
                  name="competencesTechniques"
                  value={competencesTechniques}
                  placeholder="Compétences techniques du candidat."
                  margin="normal"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  {...(errors.competencesTechniques && {
                    error: true,
                    label: errors.competencesTechniques,
                  })}
              />
            </Card>
            <Card className="cards" style={{ flex: 1 }}>
              <h4>AJOUTEZ A UNE OU PLUSIEURS SELECTIONS</h4>
              <InputLabel
                  {...(errors.disponibilite && {
                    error: true,
                  })}
                  shrink
              >
                {errors.disponibilite}
              </InputLabel>
                <label className="input-label">
                    Selection(s)<span className="required-input">*</span>
                </label>
                <FormControl variant="outlined" fullWidth margin="normal" error={!!errors.candidatSelectionsData}>
                <Select
                    multiple
                    name="selections"
                    value={candidatSelectionsData}
                    onChange={(event) => setCandidatSelectionsData(event.target.value)}
                    renderValue={(selected) =>
                        selectionsData
                            .filter((item) => selected.includes(item.id))
                            .map((selection) => {
                              return selection.selectionName;
                            })
                            .join(", ")
                    }
                >
                  {selectionsData.map((selection) => (
                      <MenuItem value={selection.id} key={selection.id}>
                        <Checkbox
                            checked={
                                candidatSelectionsData.indexOf(selection.id) > -1
                            }
                        />
                        <ListItemText primary={selection.selectionName} />
                      </MenuItem>)
                  )}
                </Select>
                    {errors.candidatSelectionsData && (
                        <FormHelperText>{errors.candidatSelectionsData}</FormHelperText>
                    )}
              </FormControl>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Card className="cards">
              <h4>DIPLOME ET LANGUE</h4>
              <label className="input-label">DIPLOME PRINCIPAL</label>
              <EcoleModal
                  errors={errors}
                  setErrors={setErrors}
                  snackbarShowMessage
                  {...{
                    setReloadData,
                    reloadData
                  }}
              />
              <Autocomplete
                  getOptionSelected={(option, value) => option.name === value.name}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    setEcole(values);
                  }}
                  options={options}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={ecole ? ecole.name : ""}
                          placeholder={ecole ? ecole.name : ""}
                          {...(errors.ecole && {
                            error: true,
                            label: errors.ecole,
                          })}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                          }}
                      />
                  )}
              />
              <label className="input-label">ANGLAIS</label>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel
                    {...(errors.anglais && {
                      error: true,
                    })}
                    shrink
                >
                  {errors.anglais}
                </InputLabel>
                <Select
                    value={anglais}
                    name="anglais"
                    onChange={(e) => setAnglais(e.target.value)}
                    {...(errors.anglais && {
                      error: true,
                    })}
                    input={
                      <OutlinedInput
                          name="anglais"
                          {...(errors.anglais && {
                            labelWidth: 260,
                          })}
                          notched
                      ></OutlinedInput>
                    }
                >
                  <MenuItem value="">
                    <em>-</em>
                  </MenuItem>
                  <MenuItem value="Courant">Courant</MenuItem>
                  <MenuItem value="Professionnel">Professionnel</MenuItem>
                  <MenuItem value="Intermediaire">Intermédiaire</MenuItem>
                  <MenuItem value="Technique">Technique</MenuItem>
                </Select>
              </FormControl>
              <label className="input-label">LANGUE 2</label>
              <TextField
                  onChange={(e) => setLangue2(e.target.value)}
                  name="langue2"
                  value={langue2}
                  placeholder="LANGUE 2"
                  margin="normal"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  {...(errors.langue2 && {
                    error: true,
                    label: errors.langue2,
                  })}
              />
            </Card>
            <Card className="cards">
              <h4>DISPONIBILITÉ</h4>
              <InputLabel
                  {...(errors.disponibilite && {
                    error: true,
                  })}
                  shrink
              >
                {errors.disponibilite}
              </InputLabel>
              <label className="input-label">PRÉAVIS</label>
              <FormControl variant="outlined" fullWidth margin="normal">
                <Select
                    value={preAvis}
                    name="preAvis"
                    onChange={(e) => handleDisponibilite(e.target.value)}
                >
                  <MenuItem value="">
                    <em>-</em>
                  </MenuItem>
                  <MenuItem value="Immédiate">Immédiate</MenuItem>
                  <MenuItem value="3 mois non négociable">
                    3 mois non négociable
                  </MenuItem>
                  <MenuItem value="2 mois">2 mois</MenuItem>
                  <MenuItem value="1 mois">1 mois</MenuItem>
                  <MenuItem value="À négocier">À négocier</MenuItem>
                </Select>
              </FormControl>
              <div style={{ display: "flex" }}>
                <Devide />
                <span style={{ fontWeight: 500 }}>OU</span>
                <Devide />
              </div>
              <label className="input-label">DATE DE DISPONIBILITE</label>
              <KeyboardDatePicker
                  style={{ marginTop: "0px" }}
                  invalidDateMessage=""
                  autoOk={true}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  margin="normal"
                  value={dispoLe}
                  onChange={(date) => handleDisponibilite(date)}
                  fullWidth
                  {...(errors.disponibilite && {
                    error: true,
                  })}
              />
            </Card>
          </Box>
        </Grid>
        {isAdmin && (
            <Grid item xs={12} sm={6}>
              <Card className="cards" style={{height: "100%"}}>
                <h4>RÉMUNÉRATION ACTUELLE</h4>
                <label className="input-label">PACKAGE</label>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel
                      {...(errors.actuPackage && {
                        error: true,
                      })}
                      shrink
                  >
                    {errors.actuPackage}
                  </InputLabel>
                  <OutlinedInput
                      value={actuPackage}
                      name="actuPackage"
                      onChange={(e) => setActuPackage(e.target.value)}
                      endAdornment={
                        <InputAdornment position="start">MAD</InputAdornment>
                      }
                      placeholder="Package"
                      {...(errors.actuPackage && {
                        error: true,
                        labelWidth: 260,
                      })}
                      notched
                  />
                </FormControl>
                <label className="input-label">FIXE</label>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel
                      {...(errors.actuFixe && {
                        error: true,
                      })}
                      shrink
                  >
                    {errors.actuFixe}
                  </InputLabel>
                  <OutlinedInput
                      value={actuFixe}
                      name="actuFixe"
                      onChange={(e) => setActuFixe(e.target.value)}
                      endAdornment={
                        <InputAdornment position="start">MAD</InputAdornment>
                      }
                      placeholder="Fixe"
                      {...(errors.actuFixe && {
                        error: true,
                        labelWidth: 225,
                      })}
                      notched
                  />
                </FormControl>
                <label className="input-label">VARIABLE</label>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel
                      {...(errors.actuVariable && {
                        error: true,
                      })}
                      shrink
                  >
                    {errors.actuVariable}
                  </InputLabel>
                  <OutlinedInput
                      value={actuVariable}
                      name="actuVariable"
                      onChange={(e) => setActuVariable(e.target.value)}
                      endAdornment={
                        <InputAdornment position="start">MAD</InputAdornment>
                      }
                      placeholder="Variable"
                      {...(errors.actuVariable && {
                        error: true,
                        labelWidth: 260,
                      })}
                      notched
                  />
                </FormControl>
                <label className="input-label">AUTRES</label>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel
                      {...(errors.actuAutres && {
                        error: true,
                      })}
                      shrink
                  >
                    {errors.actuAutres}
                  </InputLabel>
                  <OutlinedInput
                      value={actuAutres}
                      name="actuAutres"
                      onChange={(e) => setActuAutres(e.target.value)}
                      endAdornment={
                        <InputAdornment position="start">MAD</InputAdornment>
                      }
                      placeholder="Variable"
                      {...(errors.actuAutres && {
                        error: true,
                        labelWidth: 260,
                      })}
                      notched
                  />
                </FormControl>
              </Card>
            </Grid>
        )}
        {isAdmin && (
            <Grid item xs={12} sm={6}>
              <Card className="cards">
                <h4>RÉMUNÉRATION SOUHAITÉE</h4>
                <label className="input-label">PACKAGE</label>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel
                      {...(errors.souhaitPackage && {
                        error: true,
                      })}
                      shrink
                  >
                    {errors.souhaitPackage}
                  </InputLabel>
                  <OutlinedInput
                      value={souhaitPackage}
                      name="souhaitPackage"
                      onChange={(e) => setsouhaitPackage(e.target.value)}
                      endAdornment={
                        <InputAdornment position="start">MAD</InputAdornment>
                      }
                      placeholder="Package"
                      {...(errors.souhaitPackage && {
                        error: true,
                        labelWidth: 260,
                      })}
                      notched
                  />
                </FormControl>
                <label className="input-label">FIXE</label>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel
                      {...(errors.souhaitFixe && {
                        error: true,
                      })}
                      shrink
                  >
                    {errors.souhaitFixe}
                  </InputLabel>
                  <OutlinedInput
                      value={souhaitFixe}
                      name="souhaitFixe"
                      onChange={(e) => setSouhaitFixe(e.target.value)}
                      endAdornment={
                        <InputAdornment position="start">MAD</InputAdornment>
                      }
                      placeholder="Fixe"
                      {...(errors.souhaitFixe && {
                        error: true,
                        labelWidth: 225,
                      })}
                      notched
                  />
                </FormControl>
                <label className="input-label">VARIABLE</label>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel
                      {...(errors.souhaitVariable && {
                        error: true,
                      })}
                      shrink
                  >
                    {errors.souhaitVariable}
                  </InputLabel>
                  <OutlinedInput
                      value={souhaitVariable}
                      name="souhaitVariable"
                      onChange={(e) => setSouhaitVariable(e.target.value)}
                      endAdornment={
                        <InputAdornment position="start">MAD</InputAdornment>
                      }
                      placeholder="Variable"
                      {...(errors.souhaitVariable && {
                        error: true,
                        labelWidth: 270,
                      })}
                      notched
                  />
                </FormControl>
                <label className="input-label">AUTRES</label>
                <TextField
                    onChange={(e) => setSouhaitAutres(e.target.value)}
                    name="souhaitAutres"
                    value={souhaitAutres}
                    placeholder="Autres."
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                />
                <label className="input-label">TJM</label>
                <TextField
                    onChange={(e) =>setSouhaitTjm(e.target.value)}
                    name="souhaitTjm"
                    value={souhaitTjm}
                    placeholder="TJM."
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                />
              </Card>
            </Grid>)}
      </>
  );
};

const Devide = () => {
  return (
      <>
        <hr
            style={{
              height: 5,
              width: "45%",
            }}
        />
      </>
  );
};
