import React, { useState, useCallback, useEffect } from "react";
import "./verticalnavwrapper.css";
import { AiOutlineHome } from "react-icons/ai";
import { FiArchive } from "react-icons/fi";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import {
  Box,
  IconButton,
  ListItem,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import { Typography } from "@material-ui/core";
import { FaToolbox, FaUsers } from "react-icons/fa";
import { RiMailSettingsLine } from "react-icons/ri";
import { FiChevronsLeft } from "react-icons/fi";
import { FiChevronsRight } from "react-icons/fi";
import { ImCompass2, ImStatsDots } from "react-icons/im";
import { BsGear } from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import { GiOnTarget } from "react-icons/gi";
import CandidatImport from "../../pages/Import/Candidat/CandidatImport";
import { getUserData } from "../../utils/FunctionHelpers";
import { theme } from "../../utils/Theme";
import { useDispatch } from "react-redux";
import { setToggleSidebar } from "../../redux/toggle/actions/toggleActions";
import { CalendarViewWeek } from "@mui/icons-material";
import { v4 } from "uuid";


const useStyles = makeStyles({
  nestedItems: {
    fontSize: "15px",
    fontWeight: "initial",
    color: "rgb(103, 107, 132)",
    padding: "4% 0px 4% 10%",
    transition: "all 0.3s ease 0s",
    zIndex: "0",
    display: "grid",
    gridTemplateColumns: "29px 145px 35px",
    gridGap: "15px",
    gap: "15px",
    alignItems: "center",
    cursor: "pointer",
  },
  nestedText: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "rgb(103, 107, 132)",
    textDecoration: "none",
    margin: "0% 0% 0% -8%",
    fontFamily: "myFirstFont",
    letterSpacing: "1px",
  },
  nestedIcons: {
    minWidth: "25px",
  },
  styleIcones: {
    fontSize: "20px !important",
  },
  decoration: {
    textDecoration: "none !important",
  },
  nestedTexts: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "rgb(103, 107, 132)",
    paddingLeft: "35px",
    textDecoration: "none",
    fontFamily: "myFirstFont",
    letterSpacing: "1px",
    "&:hover": {
      color: "rgb(103, 107, 132) !important",
    },
  },
  linkimg: {
    textAlign: "center",
  },
  closeIcone: {
    fontSize: "26px",
    color: "#117aa7",
    float: "right",
    marginTop: "1.5%",
    marginRight: "1.5%",
  },

  text: {
    marginLeft: "30px",
  },


}, { index: 1 });

const VerticalNavWrapper = ({ sideBarCollapsed = false, handleClose = () => { } }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [todos, setTodos] = useState(false);
  const isMdMode = useMediaQuery(theme.breakpoints.between(0, 992));
  const dispatch = useDispatch();

  const [imageVisible, setImageVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setImageVisible(todos ? true : false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [todos]);

  const setToggleFun = useCallback(() => {
    setTodos(!todos);
    dispatch(setToggleSidebar(!todos))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todos]);

  const Logo = () => (
    <NavLink to="/accueil" className={classes.linkimg}>
      <img
        src={process.env.REACT_APP_LOGO_URL}
        alt="People fine Logo"
        style={{
          width: todos ? '1px' : (imageVisible ? '1px' : '150px'),
          transition: '0.5s',
        }}
      />
    </NavLink>
  );
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", justifyContent: "space-between", background: " #ffffff" }}>
      <Box sx={{ height: "30%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Logo />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between", background: " #ffffff" }}>
        <nav
          style={{ boxShadow: "none", display: "flex", marginButtom: "auto" }}
          className={classNames({
            sidebar: true,
            active_sidebar: sideBarCollapsed,
            sidebar_collapsed: todos,
          })}
        >

          <ul id="sidebar_menu" className="metismenu">
            <div>
              {[
                {
                  to: "/accueil",
                  activeClassName: "activated",
                  onClick: () => handleClose(),
                  icon: <AiOutlineHome fontSize={32} data-toggle='tooltip' title="ACCUEIL" />,
                  label: "ACCUEIL",
                },
                {
                  to: "/tachescandidat",
                  activeClassName: "activated",
                  onClick: handleClose,
                  icon: <FaTasks fontSize={32} data-toggle='tooltip' title="MES TÂCHES" />,
                  label: "MES TÂCHES",
                },
                {
                  to: "/selections",
                  activeClassName: "activated",
                  onClick: handleClose,
                  icon: <FiArchive fontSize={32} data-toggle='tooltip' title="MES SÉLECTIONS" />,
                  label: "MES SÉLECTIONS",
                },
                {
                  to: "/missions",
                  activeClassName: "activated",
                  onClick: handleClose,
                  icon: <GiOnTarget fontSize={32} data-toggle='tooltip' title="MISSIONS" />,
                  label: "MISSIONS",
                },
                // {
                //   to: "/process",
                //   activeClassName: "activated",
                //   onClick: handleClose,
                //   icon: <CalendarViewWeek fontSize="large" data-toggle='tooltip' title="PROCESS" />,
                //   label: "PROCESS",
                // },
              ].map((item) => (
                <li key={item.to}>
                  <NavLink
                    to={item.to}
                    activeClassName={item.activeClassName}
                    onClick={item.onClick}
                  >
                    <span>{item.icon}</span>
                    <span className={classes.text}>{item.label}</span>
                  </NavLink>
                </li>
              ))}

              <ListItem onClick={() => setOpen(!open)} className={classes.nestedItems}>
                <ListItemIcon className={classes.styleIcones} data-toggle='tooltip' title="OUTILS">
                  <FaToolbox fontSize={32} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography type="body2" className={classes.nestedText}>
                      <span className={classes.text}>OUTILS</span>
                    </Typography>
                  }
                />
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {[
                    { to: "/reporting/intern", text: "Reportings internes", icon: <ImStatsDots /> },
                    { to: "/pilotage", text: "Pilotage", icon: <ImCompass2 /> },
                    { to: "/reportingClient", text: "Reportings client", icon: <ImStatsDots /> },
                    { to: "/emailtype", text: "Gestion des Emails", icon: <RiMailSettingsLine /> },
                    { to: "/users", text: "Users", icon: <FaUsers />, condition: getUserData()?.role.includes('ROLE_DIRECTION') },
                    { to: "#", text: "Gestion des listes déroulantes", icon: <BsGear /> },
                  ].map((item, index) => (
                    item.condition === false ? null : (
                      <NavLink
                        key={v4()}
                        to={item.to}
                        className={classes.decoration}
                        activeClassName="activatedSubList"
                        onClick={handleClose}
                      >
                        <ListItem button className={classes.nestedTexts}>
                          <ListItemIcon className={classes.nestedIcons} data-toggle='tooltip' title={item.text}>
                            {item.icon}
                          </ListItemIcon>
                          <span style={{ marginLeft: "25px" }}>{item.text}</span>
                        </ListItem>
                      </NavLink>
                    )
                  ))}
                  <CandidatImport />
                </List>
              </Collapse>

            </div>

          </ul>
        </nav>
        {!isMdMode && <Box sx={{
          display: 'flex',
          justifyContent: "end",
          width: '100%',
          position: 'relative',
          padding: "0px 1rem 1rem 0px"
        }}>
          <IconButton onClick={setToggleFun} data-toggle="tooltip"
            title={todos ? "Elargir" : "Rétrécir"}>
            {todos ? (
              <FiChevronsRight
                className={classes.closeIcone}
              />
            ) : (
              <FiChevronsLeft
                className={classes.closeIcone}
              />
            )}
          </IconButton>
        </Box>}
      </Box>
    </Box>
  );
};

export default VerticalNavWrapper;
